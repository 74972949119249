import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';

// hooks
import useCollapseDrawer from '@/hooks/useCollapseDrawer';

import KendallLogo from '@/assets/images/logo.png';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const { isCollapse } = useCollapseDrawer();
  const style = isCollapse
    ? {
        width: 40,
        overflow: 'hidden',
        '> img': {
          width: 100,
          maxWidth: 'inherit',
        },
      }
    : { width: 100, overflow: 'visible' };

  const logo = (
    <Box sx={{ ...style, ...sx }}>
      <img src={KendallLogo} alt="kendall-logo" className="kendall-logo" />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
