import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { List, Box, ListSubheader } from '@mui/material';
//
import { NavListRoot } from './NavList';
import uuidv4 from '@/utils/uuidv4';
import useAuth from '@/hooks/useAuth';
import { PATH_DASHBOARD } from '@/routes/paths';

// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  paddingBottom: theme.spacing(1),

  color: theme.palette.text.secondary,
  opacity: 0.8,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}) {
  const { isDemoAccount } = useAuth();
  return (
    <Box {...other}>
      {navConfig.map((group) => (
        <List key={uuidv4()} disablePadding sx={{ px: 1 }}>
          <ListSubheaderStyle
            sx={{
              ...(isCollapse && {
                opacity: 0,
              }),
            }}
          >
            {group.subheader}
          </ListSubheaderStyle>

          {group.items.map((list) => {
            let isDisable = false;

            if (
              list.path === PATH_DASHBOARD.main.integrations &&
              isDemoAccount
            ) {
              isDisable = true;
            }

            return (
              <NavListRoot
                key={uuidv4()}
                list={list}
                isCollapse={isCollapse}
                isDisable={isDisable}
              />
            );
          })}
        </List>
      ))}
    </Box>
  );
}
