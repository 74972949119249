import {
  subDays,
  startOfDay,
  startOfWeek,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  endOfDay,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const calcZonedDate = (date, tz, options = null) => {
  const inputZoned = utcToZonedTime(date, tz, options);
  return inputZoned;
};

const getYesterday = () => {
  const today = new Date();
  today.setDate(today.getDate() - 1);
  return today;
};

export const getZonedStartOfYesterday = (timezone) => {
  return startOfDay(calcZonedDate(getYesterday(), timezone));
};

export const getZonedEndOfToday = (timezone) => {
  return endOfDay(calcZonedDate(new Date(), timezone));
};

export const CUSTOM_RANGE_DATE_PICKER_TIMEZONED = (timezone = '') => {
  return [
    {
      label: 'Today',
      startDate: () => startOfDay(calcZonedDate(new Date(), timezone)),
    },
    {
      label: 'Yesterday',
      startDate: () => startOfDay(calcZonedDate(getYesterday(), timezone)),
      endDate: () => endOfDay(calcZonedDate(getYesterday(), timezone)),
    },
    {
      label: 'Last 7 days',
      startDate: (today) =>
        startOfDay(calcZonedDate(subDays(today, 7), timezone)),
      endDate: () => endOfDay(calcZonedDate(getYesterday(), timezone)),
    },
    {
      label: 'Last 14 days',
      startDate: (today) =>
        startOfDay(calcZonedDate(subDays(today, 14), timezone)),
      endDate: () => endOfDay(calcZonedDate(getYesterday(), timezone)),
    },
    {
      label: 'Last 30 days',
      startDate: (today) =>
        startOfDay(calcZonedDate(subDays(today, 30), timezone)),
      endDate: () => {
        const a = endOfDay(calcZonedDate(getYesterday(), timezone));
        return a;
      },
    },
    {
      label: 'Last 90 days',
      startDate: (today) =>
        startOfDay(calcZonedDate(subDays(today, 90), timezone)),
      endDate: () => endOfDay(calcZonedDate(getYesterday(), timezone)),
    },
    {
      label: 'Last 365 days',
      startDate: (today) =>
        startOfDay(calcZonedDate(subDays(today, 365), timezone)),
      endDate: () => endOfDay(calcZonedDate(getYesterday(), timezone)),
    },
    {
      label: 'Last Month',
      startDate: (today) =>
        startOfMonth(calcZonedDate(subMonths(today, 1), timezone)),
      endDate: (today) =>
        endOfMonth(calcZonedDate(subMonths(today, 1), timezone)),
    },
    {
      label: 'Last Quarter',
      startDate: (today) =>
        startOfQuarter(calcZonedDate(subMonths(today, 3), timezone)),
      endDate: (today) =>
        endOfQuarter(calcZonedDate(subMonths(today, 3), timezone)),
    },
    {
      label: 'Last Year',
      startDate: (today) =>
        startOfYear(calcZonedDate(subMonths(today, 12), timezone)),
      endDate: (today) =>
        endOfYear(calcZonedDate(subMonths(today, 12), timezone)),
    },
    {
      label: 'This Week',
      startDate: (today) =>
        startOfWeek(calcZonedDate(today, timezone), { weekStartsOn: 1 }),
    },
    {
      label: 'This Month',
      startDate: (today) => startOfMonth(calcZonedDate(today, timezone)),
    },
    {
      label: 'This Quarter',
      startDate: (today) => startOfQuarter(calcZonedDate(today, timezone)),
    },
    {
      label: 'This Year',
      startDate: (today) => startOfYear(calcZonedDate(today, timezone)),
    },
  ];
};

export const getTodayWithTimezone = (timezone = '') => {
  return utcToZonedTime(new Date(), timezone);
};
