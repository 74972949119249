import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, Select, Stack, styled } from '@mui/material';

import { filtersSelector, setFilter } from '@/redux/slices/attribution';
import DateRangePicker from '@/components/DateRangePicker';
import useAuth from '@/hooks/useAuth';

const SmallSelect = styled(Select)({
  '.MuiSelect-select': {
    padding: '4.5px 32px 4.5px 14px',
    fontSize: 14,
    backgroundColor: '#fff',
  },
});

let platformData = [
  { value: 'facebook', label: 'Facebook' },
  { value: 'google', label: 'Google' },
  { value: 'tiktok', label: 'Tiktok' },
  { value: 'snapchat', label: 'Snapchat' },
  { value: 'microsoft', label: 'Microsoft' },
  { value: 'pinterest', label: 'Pinterest' },
];

const dateTypeData = [
  { value: 'click', label: 'Click Date' },
  { value: 'event', label: 'Event Date' },
];

const modelData = [
  { value: 'any_click', label: 'Any Click' },
  { value: 'first_click', label: 'First Click' },
  { value: 'last_click', label: 'Last Non-Direct Click' },
];

const customerTypeData = [
  { value: 'new', label: 'New Customers' },
  { value: 'returning', label: 'Returning Customers' },
];

const windowData = [
  { value: '1', label: '1 Day' },
  { value: '7', label: '7 Days' },
  { value: '14', label: '14 Days' },
  { value: '30', label: '30 Days' },
  { value: '60', label: '60 Days' },
  { value: '90', label: '90 Days' },
  { value: '180', label: '180 Days' },
];

const Filter = memo(
  ({ name, label, value, options, width, onChange, disabled }) => {
    return (
      <Stack spacing="4px" width={width} className="custom-filter-item">
        <label style={{ fontSize: 14 }}>{label}:</label>
        <SmallSelect
          value={value}
          onChange={(e) => onChange(name, e.target.value)}
          displayEmpty
          disabled={disabled || false}
        >
          {options.map((o) => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          ))}
        </SmallSelect>
      </Stack>
    );
  }
);

const Filters = ({
  visiblePlatform,
  visibleDateType,
  visibleModel,
  visibleCustomerType,
  visibleWindow,
  platformFilterDisabled,
  dateFilterDisabled,
  selectedPlatform,
  integrations,
  date_range_exclude,
  date = null,
  setDate = undefined,
  maxDate = undefined,
  minDate = undefined,
}) => {
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector);
  const { user } = useAuth();

  const changeDate = useCallback(
    (value) => {
      dispatch(setFilter({ key: 'date', value }));
    },
    [dispatch]
  );

  const changeFilter = useCallback(
    (key, value) => {
      dispatch(setFilter({ key, value }));
    },
    [dispatch]
  );

  if (Array.isArray(integrations)) {
    const integrationPlatforms = integrations.map(
      (integration) => integration.platform
    );
    platformData = platformData.filter((pd) =>
      integrationPlatforms.includes(pd.value)
    );
  }

  return (
    <Stack
      direction="row"
      spacing={3}
      flexWrap={'wrap'}
      className="custom-filter-group"
    >
      {visiblePlatform && (
        <Filter
          name="platform"
          label="Platform"
          value={selectedPlatform || filters.platform}
          options={platformData}
          onChange={changeFilter}
          disabled={platformFilterDisabled}
        />
      )}
      <Stack spacing="4px" className="custom-filter-item__date">
        <label style={{ fontSize: 14 }}>Date:</label>
        <DateRangePicker
          value={date || filters.date}
          date_range_exclude={date_range_exclude}
          maxDate={maxDate}
          minDate={minDate}
          onChange={setDate || changeDate}
          size="small"
          timezone={user?.iana_timezone}
          disabled={dateFilterDisabled}
        />
      </Stack>
      {visibleDateType && (
        <Filter
          name="dateType"
          label="Date Type"
          value={filters.dateType}
          options={dateTypeData}
          onChange={changeFilter}
          width={120}
        />
      )}
      {visibleModel && (
        <Filter
          name="model"
          label="Model"
          value={filters.model}
          options={modelData}
          onChange={changeFilter}
          width={120}
        />
      )}
      {visibleCustomerType && (
        <Filter
          name="customerType"
          label="Customer Type"
          value={filters.customerType}
          options={customerTypeData}
          onChange={changeFilter}
          width={190}
        />
      )}
      {visibleWindow && (
        <Filter
          name="window"
          label="Window"
          value={filters.window}
          options={windowData}
          onChange={changeFilter}
          width={110}
        />
      )}
    </Stack>
  );
};

export default memo(Filters);
