import React from 'react';
// @mui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import useSettings from '@/hooks/useSettings';
// components
import Page from '@/components/Page';
import ConstructionIcon from '@/assets/images/data_empty_state.png';

const ConstructionPage = () => {
  const { themeStretch } = useSettings();
  return (
    <Page>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            maxWidth: '540px',
            margin: 'auto',
          }}
        >
          <Box mb={4}>
            <img
              src={ConstructionIcon}
              alt="We're preparing your data"
              width={400}
            />
          </Box>
          <Typography variant="h4" paragraph>
            We're preparing your data
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            It may take up to 24 hours to load and process your store's data.
            This is a one-time wait. We'll email you once it's done. Thanks for
            your patience!
          </Typography>
        </Box>
      </Container>
    </Page>
  );
};

export default ConstructionPage;
