import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '@/utils/axios';
import { dispatch } from '../store';
import { onError } from './notification';
import { getSessionWithKey } from '@/utils/shopify';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  facebook_ads_data: null,
  google_ads_data: null,
  utm_issues_data: null,
};

const slice = createSlice({
  name: 'utmValidator',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET UTM ISSUES DATA
    getIssuesUtmValidationAdsDataSuccess(state, action) {
      state.isLoading = false;
      const { data, platform } = action.payload;
      if (!state.utm_issues_data) {
        state.utm_issues_data = {};
      }
      state.utm_issues_data[platform] = data;
    },

    // GET PLATFORM DATA
    getPlatformUtmValidationAdsDataSuccess(state, action) {
      state.isLoading = false;
      const { data, platform } = action.payload;
      switch (platform) {
        case 'facebook':
          state.facebook_ads_data = data;
          break;
        case 'google':
          state.google_ads_data = data;
          break;
        default:
          break;
      }
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getUtmlValidationAdsData(params) {
  const { store_id, platform } = params;
  const secret = getSessionWithKey('secret');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/utm-validation/ads-list', {
        params: {
          store_id: store_id,
          secret,
          data_for_tab: params.data_for_tab,
          platform,
        },
      });

      if (params.data_for_tab === 'issues') {
        dispatch(
          slice.actions.getIssuesUtmValidationAdsDataSuccess({
            platform,
            data: response.data?.data || response.data,
          })
        );
      } else {
        dispatch(
          slice.actions.getPlatformUtmValidationAdsDataSuccess({
            platform,
            data: response.data?.data || response.data,
          })
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}
