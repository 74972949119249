import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '@/utils/axios';
import { getSessionWithKey } from '@/utils/shopify';

// reducer

const initialState = {
  requestId: '',
  isLoading: true,
  error: null,
  data: null,
};

const slice = createSlice({
  name: 'dashboard_config',
  initialState,
  reducers: {
    setFilter(state, action) {
      state.filters[action.payload.key] = action.payload.value;
    },
    setType(state, action) {
      if (state.type !== action.payload) {
        state.data = null;
      }
      state.type = action.payload;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardConfig.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.requestId = action.meta.requestId;
      })
      .addCase(getDashboardConfig.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getDashboardConfig.rejected, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isLoading = false;
        state.data = null;
        state.error = action.error;
      })
      .addCase(updateDashboardConfig.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.requestId = action.meta.requestId;
      })
      .addCase(updateDashboardConfig.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(updateDashboardConfig.rejected, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isLoading = false;
        // state.data = null;
        state.error = action.error;
      });
  },
});

export default slice.reducer;

// actions

export const { setFilter, setType, setLoading } = slice.actions;

export const getDashboardConfig = createAsyncThunk(
  'dashboard_config',
  async ({ type, ...params }, thunkAPI) => {
    // thunkAPI.dispatch(setType(type));
    const response = await axios.get('dashboard-config', {
      params: {
        ...params,
        secret: getSessionWithKey('secret'),
      },
    });

    return response.data;
  }
);

export const updateDashboardConfig = createAsyncThunk(
  'dashboard_config_update',
  async ({ type, data, ...params }, thunkAPI) => {
    // thunkAPI.dispatch(setType(type));
    const response = await axios.patch('dashboard-config', data, {
      params: {
        ...params,
        secret: getSessionWithKey('secret'),
      },
    });

    return response.data;
  }
);
