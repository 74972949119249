import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import { styled } from '@mui/system';

import useAuth from '@/hooks/useAuth';
import SpinnerV2 from '@/components/SpinnerV2';
import { createMonthlySubscription } from '@/redux/slices/subscription';

const Title = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  gap: '0.25em',
  marginBottom: 16,
  '.MuiButtonBase-root': {
    marginLeft: 'auto',
  },
});

const SubscriptionNotice = ({ open, params, setCustomiseOpen }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const loading = useSelector((state) => state.subscription.isLoading);

  const handleCreateNewSubscription = async () => {
    console.log('user has not subscribed yet, get a subscription link');
    dispatch(
      createMonthlySubscription({
        store_id: user.store_id,
        trial_expired: true,
      })
    );
  };

  useEffect(() => {
    const rootElem = document.getElementById('root');
    if (rootElem) {
      rootElem.style.filter = `blur(2px)`;
      rootElem.style['-webkit-filter'] = `blur(2px)`;
    }
  }, []);

  return (
    <Dialog
      maxWidth={'md'}
      fullWidth
      open={open}
      onClose={() => setCustomiseOpen(false)}
    >
      <DialogContent>
        {loading && <SpinnerV2 />}
        <Title>
          <strong
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              fontSize: '1.2rem',
              marginTop: '1rem',
            }}
          >
            🎉 We hope you've been loving Kendall! 🎉
          </strong>
        </Title>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '2rem',
            marginTop: '2rem',
            padding: '2rem',
          }}
        >
          <p>
            <strong>Your Kendall free trial is up</strong>, but don't worry, you
            can continue using the app for monitoring your store's performance
            and gaining insights into how to grow your store's revenue and
            profitability.
          </p>
          <p>
            To continue using Kendall, it's <strong>just $95/month</strong>,
            regardless of your store's size. It's straightforward - there's no
            annual contract and absolutely no hidden upcharges.
          </p>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          <Button
            variant="contained"
            onClick={handleCreateNewSubscription}
            endIcon={<CallMadeIcon sx={{ fill: 'white' }} />}
            sx={{ padding: '10px' }}
            disabled={loading}
          >
            Continue with Kendall
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionNotice;
