import { format, isToday, subDays } from 'date-fns';

export function formatDate(strDate) {
  const date = new Date(strDate);
  const str1 = isToday(date) ? 'Today at' : format(new Date(date), 'M/d/yy');
  const str2 = format(new Date(date), 'h:mm a');
  return `${str1} ${str2}`;
}

export function calculateDateBetweenDays(days) {
  const endDate = new Date();
  let startDate = subDays(endDate, days);
  const formattedStartDate = format(startDate, 'MMM d, yyyy');
  const formattedEndDate = format(endDate, 'MMM d, yyyy');
  return `${formattedStartDate} to ${formattedEndDate}`;
}
