import numeral from 'numeral';
import { getSessionWithKey } from '@/utils/shopify';
import getSymbolFromCurrency from 'currency-symbol-map';
import percentile from 'percentile';
const currencyCode = getSessionWithKey('currency_code');
export const currencySymbol =
  currencyCode === 'AUD' ? 'A$' : getSymbolFromCurrency(currencyCode) || '$';

// ----------------------------------------------------------------------

export function fCurrency(number) {
  if (
    number === 0 ||
    number === '0' ||
    number === undefined ||
    number === null ||
    isNaN(number)
  ) {
    return '-';
  }
  return numeral(number)
    .format(Number.isInteger(number) ? '$0,0' : '$0,0.00')
    .replace(/\$/g, currencySymbol);
}

export function fTwoDecimalCurrency(number) {
  if (
    number === 0 ||
    number === '0' ||
    number === undefined ||
    number === null ||
    isNaN(number)
  ) {
    return '-';
  }

  return numeral(number).format('$0,0.00').replace(/\$/g, currencySymbol);
}

export function fDefaultCurrency(number) {
  // Handle undefined, null, NaN, or zero values
  if (
    number === 0 ||
    number === '0' ||
    number === undefined ||
    number === null ||
    isNaN(number)
  ) {
    return '-';
  }

  // Always format with two decimal places
  return numeral(parseFloat(number))
    .format('$0,0.00')
    .replace(/\$/g, currencySymbol);
}
export function fNumberCurrency(number) {
  if (
    number === 0 ||
    number === '0' ||
    number === undefined ||
    number === null ||
    isNaN(number)
  ) {
    return '-';
  }
  return numeral(number).format('$0,0').replace(/\$/g, currencySymbol);
}
export function fPercentFromTwoPointDecimal(number) {
  if (
    number === 0 ||
    number === '0' ||
    number === undefined ||
    number === null ||
    isNaN(number)
  ) {
    return '-';
  }
  return numeral(number / 100).format('0.00%');
}
export function fPercentFromOnePointDecimal(number) {
  if (
    number === 0 ||
    number === '0' ||
    number === undefined ||
    number === null ||
    isNaN(number)
  ) {
    return '-';
  }
  return numeral(number / 100).format('0.0%');
}

export function fPercent(number, format) {
  return numeral(number / 100).format(format || '0.0%');
}

export function fPercentFromDecimal(number, format) {
  return numeral(number).format(format || '0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fNumberShortenForChart(number, precision = 0) {
  const val = Math.abs(Math.floor(number));
  let str = '';

  if (val >= 10 ** 3 && val < 10 ** 6) {
    str = parseFloat((val / 1000).toFixed(precision)) + 'K';
  } else if (val >= 10 ** 6) {
    str = parseFloat((val / 1000000).toFixed(precision)) + ' M';
  } else {
    str = val.toString();
  }

  return `${str}`;
}

export function fDecimal(number) {
  if (
    number === 0 ||
    number === '0' ||
    number === undefined ||
    number === null ||
    isNaN(number)
  ) {
    return '-';
  }
  const format = Number.isInteger(number) ? '0,0' : '0,0.00';
  return numeral(number).format(format);
}

export function formatToFixed(number, precision = 1) {
  if (number === undefined || number === null || isNaN(number)) {
    return '-';
  }
  return number.toFixed(precision);
}

export function calculatePercentile(valueArray, dataArray) {
  const percentiles = percentile(valueArray, dataArray);
  const percentileObject = {};
  valueArray.forEach((item, index) => {
    percentileObject[`p${item}`] = percentiles[index];
  });
  return percentileObject;
}
