import { AnalyticsBrowser } from '@june-so/analytics-next';
import { getSessionWithKey } from './shopify';

export const analytics = AnalyticsBrowser.load({
  writeKey: process.env.REACT_APP_JUNE_KEY,
});

export const trackIdentity = ({ store_id, name, email, logo }) => {
  const isInternalUser = getSessionWithKey('internalUser');
  if (isInternalUser) return;
  analytics
    .identify(store_id, {
      email: email,
      name: name,
      avatar: logo,
    })
    .catch((err) => console.error(err));
};

export const trackPageAnalytics = (params = {}) => {
  params = { ...params };
  const isInternalUser = getSessionWithKey('internalUser');
  // console.log({ isInternalUser });
  if (isInternalUser) return;
  if (params) {
    delete params.store_id;
    delete params.secret;
  }
  setTimeout(() => {
    const docTitle = params.pageTitle || document.title.split('|')[0].trim();
    const additionalParams = {
      page: {
        path: window.location.pathname,
        referrer: document.referrer || '',
        search: window.location.search,
        title: document.title,
        url: window.location.href,
      },
    };
    if (typeof params === 'object' && Object.keys(params).length) {
      analytics
        .track(docTitle, { ...params, ...additionalParams })
        .catch((err) => console.error(err));
    } else {
      analytics
        .page('Kendall', docTitle, additionalParams)
        .catch((err) => console.error(err));
    }
  }, 500);
};
