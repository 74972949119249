import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '@/utils/axios';
//
import { dispatch } from '../store';
import { onError, onSuccess } from './notification';
import { checkPlatformsIntegration } from '@/utils/onboardingStates';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  userInfo: null,
  integrations: null,
  failed_integrations: null,
  auto_disconnected_platform: null,
  fbAdsAcounts: [],
  ggAdsAcounts: [],
  ggAnalyticsAcounts: [],
  tiktokAdsAccounts: [],
  snapchatAdsAccounts: [],
  pinterestAdsAccounts: [],
  microsoftAdsAccounts: [],
  amazonAdsAccounts: [],
  amazonSPAccounts: [],
  dataGGAdsAccounts: null,
  dataGGAnalyticsAccounts: null,
  isFetchGGAdsAccounts: true,
  isFetchGGAnalyticsAccounts: true,
  isFetchingTiktokAccounts: false,
  isFetchingFBAccounts: false,
  isFetchingSnapchatAccounts: false,
  isFetchingPinterestAccounts: false,
  isFetchingMicrosoftAccounts: false,
  isFetchingAmazonAccounts: false,
  isFetchingAmazonAdsAccounts: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    endLoading(state) {
      state.isLoading = false;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA
    getUserInfoSuccess(state, action) {
      state.isLoading = false;
      state.userInfo = action.payload;
    },

    saveFBAccessTokenSuccess(state, action) {
      state.isLoading = false;
    },

    removeFBAccessTokenSuccess(state, action) {
      state.isLoading = false;
      state.integrations = state.integrations.filter(
        (item) => item.platform !== 'facebook'
      );
    },

    getIntegrationsSuccess(state, action) {
      state.isLoading = false;
      if (action.payload && action.payload.active) {
        state.integrations = action.payload.active;
        state.failed_integrations = action.payload.failed;
      } else {
        state.integrations = action.payload;
      }
    },

    setAutoDisconnectedPlatform(state, action) {
      state.auto_disconnected_platform = action.payload;
    },

    getFBAdsAccountSuccess(state, action) {
      state.isLoading = false;
      state.fbAdsAcounts = action.payload;
    },

    startFetchFBAccounts(state, action) {
      state.isFetchingFBAccounts = true;
    },

    endFetchFBAccounts(state, action) {
      state.isFetchingFBAccounts = false;
      state.isLoading = false;
    },

    FBAuthSuccess(state, action) {
      state.isLoading = false;
      state.fbAdsAcounts = action.payload;
    },

    FBDisabledSuccess(state, action) {
      state.isLoading = false;
      state.fbAdsAcounts = [];
      state.isFetchingFBAccounts = false;
    },

    saveGGAdsAccessTokenSuccess(state, action) {
      state.isLoading = false;
    },

    saveGGAdsSelectedAccountSuccess(state, action) {
      state.isLoading = false;
    },

    saveGGAnalyticsAccessTokenSuccess(state, action) {
      state.isLoading = false;
    },

    removeGGAdsAccessTokenSuccess(state, action) {
      state.isLoading = false;
      state.integrations = state.integrations.filter(
        (item) => item !== 'google'
      );
    },

    removeGGAnalyticsAccessTokenSuccess(state, action) {
      state.isLoading = false;
      state.integrations = state.integrations.filter(
        (item) => item.platform !== 'google-analytics'
      );
    },

    startFetchGGAdsAccounts(state, action) {
      state.isFetchGGAdsAccounts = true;
    },

    startFetchGGAnalyticsAccounts(state, action) {
      state.isFetchGGAnalyticsAccounts = true;
    },

    endFetchGGAdsAccounts(state, action) {
      state.isFetchGGAdsAccounts = false;
    },

    endFetchGGAnalyticsAccounts(state, action) {
      state.isFetchGGAnalyticsAccounts = false;
    },

    setDataGGAdsAccounts(state, action) {
      state.dataGGAdsAccounts = action.payload;
    },

    setDataGGAnalyticsAccounts(state, action) {
      state.dataGGAnalyticsAccounts = action.payload;
    },

    ggAdsAuthSuccess(state, action) {
      state.isLoading = false;
      state.isFetchGGAdsAccounts = false;
      state.ggAdsAcounts = action.payload;
    },

    getGGAnalyticsAccountSuccess(state, action) {
      state.isLoading = false;
      state.isFetchGGAnalyticsAccounts = false;
      state.ggAnalyticsAcounts = action.payload;
    },

    saveTiktokSelectedAccountSuccess(state, action) {
      state.isLoading = false;
    },

    tiktokAuthSuccess(state, action) {
      state.isLoading = false;
      state.tiktokAdsAccounts = action.payload.map((a) => {
        a.id = a.advertiser_id;
        a.name = a.advertiser_name;
        return a;
      });
    },

    startFetchTiktokAccounts(state, action) {
      state.isFetchingTiktokAccounts = true;
    },

    endFetchTiktokAccounts(state, action) {
      state.isFetchingTiktokAccounts = false;
    },

    // start snapchat
    saveSnapchatSelectedAccountSuccess(state, action) {
      state.isLoading = false;
    },

    snapchatAuthSuccess(state, action) {
      state.isLoading = false;
      state.snapchatAdsAccounts = action.payload;
    },

    startFetchSnapchatAccounts(state, action) {
      state.isFetchingSnapchatAccounts = true;
    },

    endFetchSnapchatkAccounts(state, action) {
      state.isFetchingSnapchatAccounts = false;
    },
    // end snapchat

    // start pinterest
    savePinterestSelectedAccountSuccess(state, action) {
      state.isLoading = false;
    },

    pinterestAuthSuccess(state, action) {
      state.isLoading = false;
      state.pinterestAdsAccounts = action.payload;
    },

    startFetchPinterestAccounts(state, action) {
      state.isFetchingPinterestAccounts = true;
    },

    endFetchPinterestkAccounts(state, action) {
      state.isFetchingPinterestAccounts = false;
    },
    // end pinterest

    amazonAuthSuccess(state, action) {
      state.isLoading = false;
      state.amazonSPAccounts = action.payload.map((a) => {
        a.id = a.marketplace.id;
        a.name = a.marketplace.name;
        return a;
      });
    },

    startFetchAmazonAccounts(state, action) {
      state.isFetchingAmazonAccounts = true;
    },

    endFetchAmazonAccounts(state, action) {
      state.isFetchingAmazonAccounts = false;
    },
    // start microsoft
    saveMicrosoftSelectedAccountSuccess(state, action) {
      state.isLoading = false;
    },

    microsoftAuthSuccess(state, action) {
      state.isLoading = false;
      state.microsoftAdsAccounts = action.payload;
    },

    startFetchMicrosoftAccounts(state, action) {
      state.isFetchingMicrosoftAccounts = true;
    },

    endFetchMicrosoftkAccounts(state, action) {
      state.isFetchingMicrosoftAccounts = false;
    },

    // start amazon ads
    saveAmazonAdsSelectedAccountSuccess(state, action) {
      state.isLoading = false;
    },

    amazonAdsAuthSuccess(state, action) {
      state.isLoading = false;
      state.amazonAdsAccounts = action.payload.map((a) => {
        a.id = a.profileId;
        a.name = a.accountInfo.name;
        a.advertiserId = a.accountInfo.id;
        return a;
      });
    },

    startFetchAmazonAdsAccounts(state, action) {
      state.isFetchingAmazonAdsAccounts = true;
    },

    endFetchAmazonAdsAccounts(state, action) {
      state.isFetchingAmazonAdsAccounts = false;
    },

    saveAmazonSelectedAccountSuccess(state, action) {
      state.isLoading = false;
    },
    // end amazon ads
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getUserInfo(params) {
  const { user_email } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_userinfo', {
        params: {
          user_email,
        },
      });

      dispatch(slice.actions.getUserInfoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function saveFBAccessToken(params) {
  const {
    accessToken,
    data_access_expiration_time,
    expiresIn,
    signedRequest,
    userID,
    store_id,
    adAccountId,
  } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/integrations', {
        store_id,
        platform: 'facebook',
        accessToken,
        data_access_expiration_time,
        expiresIn,
        signedRequest,
        userID,
        adAccountId,
      });

      dispatch(slice.actions.saveFBAccessTokenSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function saveFBAccessTokenV2(params) {
  const { store_id, adAccountIds, failed_integrations } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/integrations/facebook/set-account', {
        store_id,
        selectedAccountIds: adAccountIds,
      });

      dispatch(slice.actions.saveFBAccessTokenSuccess(response.data));
      if (checkPlatformsIntegration(failed_integrations, ['facebook'])) {
        dispatch(
          onSuccess(
            `Integration success! We're now re-connecting your Ad Manager account. Your reports will be refreshed within an hour.`
          )
        );
      } else {
        dispatch(onSuccess('Integration Success!'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function getIntegrations(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/integrations', {
        params: {
          store_id,
        },
      });
      dispatch(slice.actions.getIntegrationsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function removeFBAccessToken(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/integrations', {
        store_id,
        platform: 'facebook',
        disable: 1,
      });

      dispatch(slice.actions.removeFBAccessTokenSuccess(response.data));
      dispatch(getIntegrations({ store_id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function getFBAdsAccount(params) {
  const { access_token } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/fetch_fb_adaccounts', {
        access_token,
      });

      dispatch(slice.actions.getFBAdsAccountSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function facebookAuth(params) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchFBAccounts());
    try {
      const response = await axios.post('/integrations/facebook/auth', params);
      const advertisers = response.data.data?.advertisers || [];
      dispatch(slice.actions.FBAuthSuccess(advertisers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchFBAccounts());
    }
  };
}

export function removeFBAccount(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchFBAccounts());
    try {
      const response = await axios.post(
        '/integrations/facebook/remove-account',
        {
          store_id,
        }
      );
      dispatch(slice.actions.FBDisabledSuccess(response.data));
      dispatch(slice.actions.setAutoDisconnectedPlatform('facebook'));
      dispatch(getIntegrations({ store_id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchFBAccounts());
    }
  };
}

export function ggAdsAuth(params) {
  const { store_id, code } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    // dispatch(slice.actions.startFetchTiktokAccounts());
    try {
      const response = await axios.post('/integrations/google/auth', {
        store_id,
        code: code,
      });
      const advertisers = response.data.data?.advertisers || [];
      dispatch(slice.actions.ggAdsAuthSuccess(advertisers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endLoading());
    }
  };
}

export function saveGGAdsSelectedAccount(params) {
  const { store_id, selectedAdAccountIds, failed_integrations } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/integrations/google/set-account', {
        store_id,
        selectedAccountIds: selectedAdAccountIds,
      });
      dispatch(slice.actions.saveGGAdsSelectedAccountSuccess(response.data));

      if (checkPlatformsIntegration(failed_integrations, ['google'])) {
        dispatch(
          onSuccess(
            `Integration success! We're now re-connecting your Ad Manager account. Your reports will be refreshed within an hour.`
          )
        );
      } else {
        dispatch(onSuccess('Integration Success!'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endLoading());
      // dispatch(slice.actions.endFetchTiktokAccounts());
    }
  };
}

// export function saveGGAdsAccessToken(params) {
//   const { store_id, adAccountId, accessToken, refreshToken, expiresIn } =
//     params;
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.post('/integrations', {
//         store_id,
//         platform: 'google',
//         adAccountId,
//         refreshToken,
//         use_existing_token: !!refreshToken ? 0 : 1,
//         accessToken,
//         expiresIn,
//       });
//       dispatch(slice.actions.saveGGAdsAccessTokenSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//       dispatch(onError());
//     }
//   };
// }

export function removeGGAdsAccessToken(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/integrations/google/remove-account', {
        store_id,
      });
      dispatch(slice.actions.removeGGAdsAccessTokenSuccess(response.data));
      dispatch(slice.actions.setAutoDisconnectedPlatform('google'));
      dispatch(getIntegrations({ store_id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

// export function getGGAdsAccount(params) {
//   const { refreshToken, accessToken, expiresIn } = params;
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     dispatch(slice.actions.startFetchGGAdsAccounts());
//     dispatch(
//       slice.actions.setDataGGAdsAccounts({
//         accessToken,
//         refreshToken,
//         expiresIn,
//       })
//     );
//     try {
//       const response = await axios.post('/fetch_google_adaccounts', {
//         refresh_token: accessToken,
//         use_existing_token: !!refreshToken ? 0 : 1,
//       });

//       dispatch(slice.actions.getGGAdsAccountSuccess(response.data));
//       dispatch(slice.actions.endFetchGGAdsAccounts());
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//       dispatch(onError());
//     }
//   };
// }

export function saveGGAnalyticsAccessToken(params) {
  const {
    store_id,
    accountId,
    accessToken,
    refreshToken,
    expiresIn,
    profileId,
    propertyId,
  } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/integrations', {
        store_id,
        platform: 'google-analytics',
        accountId,
        refreshToken,
        accessToken,
        expiresIn,
        profileId,
        propertyId,
      });
      dispatch(slice.actions.saveGGAnalyticsAccessTokenSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function removeGGAnalyticsAccessToken(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/integrations', {
        store_id,
        platform: 'google-analytics',
        disable: 1,
      });
      dispatch(
        slice.actions.removeGGAnalyticsAccessTokenSuccess(response.data)
      );
      dispatch(getIntegrations({ store_id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function getGGAnalyticsAccount(params) {
  const { refreshToken, accessToken, expiresIn } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchGGAnalyticsAccounts());
    dispatch(
      slice.actions.setDataGGAnalyticsAccounts({
        accessToken,
        refreshToken,
        expiresIn,
      })
    );
    try {
      const response = await axios.post('/fetch_google_analyticsaccounts', {
        refresh_token: accessToken,
      });

      dispatch(slice.actions.getGGAnalyticsAccountSuccess(response.data));
      dispatch(slice.actions.endFetchGGAnalyticsAccounts());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function tiktokAuth(params) {
  const { store_id, code } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchTiktokAccounts());
    try {
      const response = await axios.post('/integrations/tiktok/auth', {
        store_id,
        platform: 'tiktok',
        code: code,
      });
      const advertisers = response.data.data?.advertisers || [];
      dispatch(slice.actions.tiktokAuthSuccess(advertisers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchTiktokAccounts());
    }
  };
}

export function saveTiktokSelectedAccount(params) {
  const { store_id, selectedAdAccountIds, failed_integrations } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchTiktokAccounts());
    try {
      const response = await axios.post('/integrations/tiktok/set-account', {
        store_id,
        platform: 'tiktok',
        selectedAccountIds: selectedAdAccountIds,
      });
      dispatch(slice.actions.saveTiktokSelectedAccountSuccess(response.data));

      if (checkPlatformsIntegration(failed_integrations, ['tiktok'])) {
        dispatch(
          onSuccess(
            `Integration success! We're now re-connecting your Ad Manager account. Your reports will be refreshed within an hour.`
          )
        );
      } else {
        dispatch(onSuccess('Integration Success!'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchTiktokAccounts());
    }
  };
}

export function removeTiktokAccount(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchTiktokAccounts());
    try {
      const response = await axios.post('/integrations/tiktok/remove-account', {
        store_id,
        platform: 'tiktok',
      });
      dispatch(slice.actions.saveTiktokSelectedAccountSuccess(response.data));
      dispatch(slice.actions.setAutoDisconnectedPlatform('tiktok'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchTiktokAccounts());
    }
  };
}

export function snapchatAuth(params) {
  const { store_id, code } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchSnapchatAccounts());
    try {
      const response = await axios.post('/integrations/snapchat/auth', {
        store_id,
        platform: 'snapchat',
        code: code,
      });
      const advertisers = response.data.data?.advertisers || [];
      dispatch(slice.actions.snapchatAuthSuccess(advertisers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchSnapchatkAccounts());
    }
  };
}

export function saveSnapchatSelectedAccount(params) {
  const { store_id, selectedAdAccountIds, failed_integrations } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchSnapchatAccounts());
    try {
      const response = await axios.post('/integrations/snapchat/set-account', {
        store_id,
        platform: 'snapchat',
        selectedAccountIds: selectedAdAccountIds,
      });
      dispatch(slice.actions.saveSnapchatSelectedAccountSuccess(response.data));

      if (checkPlatformsIntegration(failed_integrations, ['snapchat'])) {
        dispatch(
          onSuccess(
            `Integration success! We're now re-connecting your Ad Manager account. Your reports will be refreshed within an hour.`
          )
        );
      } else {
        dispatch(onSuccess('Integration Success!'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchSnapchatkAccounts());
    }
  };
}

export function removeSnapchatAccount(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchSnapchatAccounts());
    try {
      const response = await axios.post(
        '/integrations/snapchat/remove-account',
        {
          store_id,
          platform: 'snapchat',
        }
      );
      dispatch(slice.actions.saveSnapchatSelectedAccountSuccess(response.data));
      dispatch(slice.actions.setAutoDisconnectedPlatform('snapchat'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchSnapchatkAccounts());
    }
  };
}

export function pinterestAuth(params) {
  const { store_id, code } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchPinterestAccounts());
    try {
      const response = await axios.post('/integrations/pinterest/auth', {
        store_id,
        platform: 'pinterest',
        code: code,
      });
      const advertisers = response.data.data?.advertisers || [];
      dispatch(slice.actions.pinterestAuthSuccess(advertisers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchPinterestkAccounts());
    }
  };
}

export function amazonAuth(params) {
  const { store_id, code, platform } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchAmazonAccounts());
    try {
      const response = await axios.post('/integrations/amazon/auth', {
        store_id,
        platform: platform || 'amazon-sp-na',
        code: code,
      });
      const advertisers = response.data.data?.advertisers || [];
      dispatch(slice.actions.amazonAuthSuccess(advertisers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchAmazonAccounts());
    }
  };
}

export function savePinterestSelectedAccount(params) {
  const { store_id, selectedAdAccountIds, failed_integrations } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchPinterestAccounts());
    try {
      const response = await axios.post('/integrations/pinterest/set-account', {
        store_id,
        platform: 'pinterest',
        selectedAccountIds: selectedAdAccountIds,
      });
      dispatch(
        slice.actions.savePinterestSelectedAccountSuccess(response.data)
      );

      if (checkPlatformsIntegration(failed_integrations, ['pinterest'])) {
        dispatch(
          onSuccess(
            `Integration success! We're now re-connecting your Ad Manager account. Your reports will be refreshed within an hour.`
          )
        );
      } else {
        dispatch(onSuccess('Integration Success!'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchPinterestkAccounts());
    }
  };
}

export function microsoftAuth(params) {
  const { store_id, code } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchMicrosoftAccounts());
    try {
      const response = await axios.post('/integrations/microsoft/auth', {
        store_id,
        platform: 'microsoft',
        code: code,
      });
      const advertisers = response.data.data?.advertisers || [];
      dispatch(slice.actions.microsoftAuthSuccess(advertisers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchMicrosoftkAccounts());
    }
  };
}

export function saveAmazonSelectedAccount(params) {
  const {
    store_id,
    marketplaceParticipationIds,
    failed_integrations,
    platform,
  } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchAmazonAccounts());
    try {
      const response = await axios.post('/integrations/amazon/set-account', {
        store_id,
        platform: platform || 'amazon-sp-na',
        marketplaceParticipationIds: marketplaceParticipationIds,
      });
      dispatch(slice.actions.saveAmazonSelectedAccountSuccess(response.data));

      if (
        checkPlatformsIntegration(failed_integrations, [
          platform || 'amazon-sp-na',
        ])
      ) {
        dispatch(
          onSuccess(
            `Integration success! We're now re-connecting your Ad Manager account. Your reports will be refreshed within an hour.`
          )
        );
      } else {
        dispatch(onSuccess('Integration Success!'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchAmazonAccounts());
    }
  };
}

export function removePinterestAccount(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchPinterestAccounts());
    try {
      const response = await axios.post(
        '/integrations/pinterest/remove-account',
        {
          store_id,
          platform: 'pinterest',
        }
      );
      dispatch(slice.actions.pinterestAuthSuccess([]));
      dispatch(slice.actions.setAutoDisconnectedPlatform('pinterest'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchPinterestkAccounts());
    }
  };
}

export function saveMicrosoftSelectedAccount(params) {
  const { store_id, selectedAdAccountIds, failed_integrations } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchMicrosoftAccounts());
    try {
      const response = await axios.post('/integrations/microsoft/set-account', {
        store_id,
        platform: 'microsoft',
        selectedAccountIds: selectedAdAccountIds,
      });
      dispatch(
        slice.actions.saveMicrosoftSelectedAccountSuccess(response.data)
      );

      if (checkPlatformsIntegration(failed_integrations, ['microsoft'])) {
        dispatch(
          onSuccess(
            `Integration success! We're now re-connecting your Ad Manager account. Your reports will be refreshed within an hour.`
          )
        );
      } else {
        dispatch(onSuccess('Integration Success!'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchMicrosoftkAccounts());
    }
  };
}

export function removeAmazonAccount(params) {
  const { store_id, platform } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchAmazonAccounts());
    try {
      const response = await axios.post('/integrations/amazon/remove-account', {
        store_id,
        platform: platform || 'amazon-sp-na',
      });
      dispatch(slice.actions.saveAmazonSelectedAccountSuccess(response.data));
      dispatch(
        slice.actions.setAutoDisconnectedPlatform(platform || 'amazon-sp-na')
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchAmazonAccounts());
    }
  };
}

export function removeMicrosoftAccount(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchMicrosoftAccounts());
    try {
      const response = await axios.post(
        '/integrations/microsoft/remove-account',
        {
          store_id,
          platform: 'microsoft',
        }
      );
      dispatch(slice.actions.microsoftAuthSuccess([]));
      dispatch(slice.actions.setAutoDisconnectedPlatform('microsoft'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchMicrosoftkAccounts());
    }
  };
}

export function amazonAdsAuth(params) {
  const { store_id, code } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchAmazonAdsAccounts());
    try {
      const response = await axios.post('/integrations/amazon-ads/auth', {
        store_id,
        platform: 'amazon',
        code: code,
      });
      const profiles = response.data.data?.profiles || [];
      dispatch(slice.actions.amazonAdsAuthSuccess(profiles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchAmazonAdsAccounts());
    }
  };
}

export function saveAmazonAdsSelectedAccount(params) {
  const { store_id, selectedAdAccountIds, advertiserIds, failed_integrations } =
    params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchAmazonAdsAccounts());
    try {
      const response = await axios.post(
        '/integrations/amazon-ads/set-account',
        {
          store_id,
          platform: 'amazon',
          selectedAccountIds: selectedAdAccountIds,
          advertiserIds,
        }
      );
      dispatch(
        slice.actions.saveAmazonAdsSelectedAccountSuccess(response.data)
      );

      if (checkPlatformsIntegration(failed_integrations, ['amazon'])) {
        dispatch(
          onSuccess(
            `Integration success! We're now re-connecting your Ad Manager account. Your reports will be refreshed within an hour.`
          )
        );
      } else {
        dispatch(onSuccess('Integration Success!'));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchAmazonAdsAccounts());
    }
  };
}

export function removeAmazonAdsAccount(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startFetchAmazonAdsAccounts());
    try {
      const response = await axios.post(
        '/integrations/amazon-ads/remove-account',
        {
          store_id,
          platform: 'amazon',
        }
      );
      dispatch(
        slice.actions.saveAmazonAdsSelectedAccountSuccess(response.data)
      );
      dispatch(slice.actions.setAutoDisconnectedPlatform('amazon'));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    } finally {
      dispatch(slice.actions.endFetchAmazonAdsAccounts());
    }
  };
}
