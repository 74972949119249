export const resizeColumns = ({ worksheet }) => {
  const minWidth = 10;
  worksheet.columns.forEach(function (column, i) {
    let maxLength = 0;
    column['eachCell']({ includeEmpty: true }, function (cell) {
      const columnLength = cell.value
        ? cell.value.toString().length + 2
        : minWidth;
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
    });
    maxLength = Math.min(maxLength, 40);
    column.width = maxLength < minWidth ? minWidth : maxLength;
  });
};

export const exceljsPreProcess = ({ workbook, worksheet }) => {
  workbook.properties.outlineLevelCol = 1;
  worksheet.properties.outlineLevelRow = 1;
  // Set document meta data
  workbook.creator = 'Kendall';
  workbook.created = new Date();

  // Customize default excel properties
  worksheet.properties.defaultRowHeight = 30;
};

export const exceljsPostProcessWrapper = ({
  // worksheet,
  boldCellNums = [],
  shouldBoldSecondRow = false,
  leftAlignCells = [],
}) => {
  return function exceljsPostProcess({ worksheet }) {
    worksheet.eachRow(function (Row, rowNum) {
      Row.eachCell(function (Cell, cellNum) {
        Cell.font = {
          name: 'Arial',
          size: 12,
        };

        if (Array.isArray(boldCellNums) && boldCellNums.includes(cellNum)) {
          Cell.font = {
            name: 'Arial Black',
            size: 12,
          };
        }

        if (
          (Array.isArray(leftAlignCells) &&
            leftAlignCells.length &&
            leftAlignCells.includes(cellNum)) ||
          cellNum <= 2
        ) {
          Cell.alignment = {
            vertical: 'top',
            wrapText: true,
            horizontal: 'left',
          };
          return;
        }

        // else center top align cells
        Cell.alignment = {
          vertical: 'top',
          horizontal: 'center',
          wrapText: true,
        };
      });
    });

    for (let i = 1; i < 25; i++) {
      const cell = worksheet.getCell(`${String.fromCharCode(64 + i)}1`);
      cell.width = 100;
      cell.font = {
        name: 'Arial Black',
        size: 12,
      };
      cell.alignment = {
        vertical: 'top',
        horizontal: 'center',
        wrapText: true,
      };

      if (shouldBoldSecondRow) {
        const cell2 = worksheet.getCell(`${String.fromCharCode(64 + i)}2`);
        cell2.font = {
          name: 'Arial Black',
          size: 12,
        };
        cell2.alignment = {
          vertical: 'top',
          horizontal: 'center',
          wrapText: true,
        };
      }
    }

    resizeColumns({ worksheet });
  };
};
