import PropTypes from 'prop-types';
// @mui
import { List, Box } from '@mui/material';
//
import { NavListRoot } from '../vertical-v2/NavList';
import uuidv4 from '@/utils/uuidv4';
import useAuth from '@/hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrations } from '@/redux/slices/user';
import { useEffect } from 'react';
// import { dispatch } from '@/redux/store';
import { isStateBetweenZeroAndTwo } from '@/utils/onboardingStates';

// ----------------------------------------------------------------------

NavSectionVerticalVer2.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVerticalVer2({
  navConfig,
  isCollapse = true,
  ...other
}) {
  const dispatch = useDispatch();
  const { isDemoAccount, user } = useAuth();
  let updatedNavList = isDemoAccount
    ? navConfig.filter((item) => item.title !== 'Settings')
    : navConfig;

  // new onboarding flow
  const integrations = useSelector((state) => state.user.integrations);
  const storeId = user?.store_id;
  const userIsActive = user?.active;

  useEffect(() => {
    if (storeId && !Array.isArray(integrations)) {
      dispatch(getIntegrations({ store_id: storeId }));
    }
  }, [dispatch, storeId, integrations]);

  const isUserOnboarding =
    !isDemoAccount && isStateBetweenZeroAndTwo(userIsActive, integrations);

  if (isUserOnboarding && !isDemoAccount) {
    updatedNavList = updatedNavList.map((item) => {
      return item.title === 'Settings'
        ? { ...item, collapsed: false } // expand setting nav when user is onboarding
        : item.children
        ? {
            ...item,
            children: item.children.map((child) => ({
              ...child,
              disabled: true,
            })),
            disabled: true,
          }
        : {
            ...item,
            disabled: true,
          };
    });
  }
  return (
    <Box sx={{ mt: '20px' }} {...other}>
      {updatedNavList.map((item) => (
        <List
          component="div"
          key={uuidv4()}
          disablePadding
          sx={{ px: 1, mb: '15px' }}
        >
          <NavListRoot key={uuidv4()} list={item} isCollapse={isCollapse} />
        </List>
      ))}
    </Box>
  );
}
