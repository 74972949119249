import { memo, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Chip, IconButton, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import {
  fCurrency,
  formatToFixed,
  fPercent,
  fNumber,
} from '@/utils/formatNumber';
import {
  allSelector,
  errorSelector,
  isLoadingSelector,
} from '@/redux/slices/allSources';
import {
  useGridApiContext,
  useGridApiRef,
  useGridRootProps,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium';
import FacebookLogo from '@/assets/images/facebook-logo.png';
import GoogleLogo from '@/assets/images/google-logo.png';
import TiktokLogo from '@/assets/images/tiktok-icon.png';
import SnapchatLogo from '@/assets/images/snapchat-logo.png';
import MicrosoftLogo from '@/assets/images/microsoft-logo.png';
import Filters from '../../attribution/filters';
import SearchBox from '../search-box';
import { OptionalLink } from './table';
// import AttributionSettings from '../filters/attributionSettings';
import {
  BoxDataGridStyleV2,
  gridTableBorderStyle,
} from '../../adsCreativePerformance/AdsCreativePerformanceSectionStyle';
import DataGridPremiumCustom from '@/components/DataGridPremiumCustom';
import {
  exceljsPostProcessWrapper,
  exceljsPreProcess,
} from '@/utils/exportUtils';

const Container = styled('span')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

const sourceIcons = {
  'Facebook Ads': FacebookLogo,
  'Google Ads': GoogleLogo,
  'TikTok Ads': TiktokLogo,
  'Snapchat Ads': SnapchatLogo,
  'Microsoft Ads': MicrosoftLogo,
};

const getTreeDataPath = (row) => row.hierarchy;

const getRowId = (row) => row.id;

const excelOptions = {
  exceljsPreProcess: exceljsPreProcess,
  exceljsPostProcess: exceljsPostProcessWrapper({
    boldCellNums: [],
  }),
  getRowsToExport: (params) => {
    const allRows = [...params.apiRef.current.getRowModels()];
    return allRows
      .filter((r) => r[0].indexOf('placeholder-children') !== 0)
      .map((r) => r[0]);
  },
};

const PlatformTH = ({ label }) => {
  return (
    <div style={{ display: 'grid', lineHeight: '1.5' }}>
      <div
        style={{
          display: 'inline-flex',
          textAlign: 'center',
          fontWeight: 700,
          minHeight: '45px',
          paddingTop: '2px',
        }}
      >
        <span>{label}</span>
      </div>
    </div>
  );
};

function GroupingCellWithLazyLoading(props) {
  const { id, rowNode, row, formattedValue } = props;
  const rootProps = useGridRootProps();
  const apiRef = useGridApiContext();

  const newFormattedValue =
    (row.hidden_breakdown && row.hidden_breakdown !== 'TOTAL'
      ? row.hidden_breakdown
      : row.hidden_source && row.hidden_source !== 'TOTAL'
      ? row.hidden_source
      : formattedValue) || 'TOTAL';

  const Icon = rowNode.childrenExpanded
    ? rootProps.slots.treeDataCollapseIcon
    : rootProps.slots.treeDataExpandIcon;

  const handleClick = () => {
    if (rowNode.type !== 'group') {
      // Cannot expand/collapse node of type;
      return;
    }
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
  };
  const icon = sourceIcons[newFormattedValue];
  return (
    <Box sx={{ ml: rowNode.depth * 2, width: '100%' }}>
      <Container sx={{ width: '100%' }}>
        {row.descendant_count > 0 && (
          <IconButton
            size="small"
            onClick={handleClick}
            tabIndex={-1}
            aria-label={
              rowNode.childrenExpanded
                ? apiRef.current.getLocaleText('treeDataCollapse')
                : apiRef.current.getLocaleText('treeDataExpand')
            }
          >
            <Icon fontSize="inherit" />
          </IconButton>
        )}
        {row.descendant_count === 0 && (
          <span
            style={{
              marginLeft: rowNode.depth * 15 + !rowNode.depth ? 27 : 45,
            }}
          ></span>
        )}
        <span
          style={{
            marginLeft: 2.5,
            wordBreak: 'break-word',
            width: '100%',
            display: 'inline-flex',
          }}
        >
          {newFormattedValue === undefined
            ? rowNode.groupingKey
            : newFormattedValue}
          {icon && (
            <img
              src={icon}
              alt="ads"
              style={{
                width: '1.2em',
                height: '1.2em',
                marginLeft: '0.2em',
              }}
            />
          )}
        </span>
      </Container>
    </Box>
  );
}

function CustomExportToolbar({ loading }) {
  return (
    <GridToolbarContainer>
      {!loading && (
        <GridToolbarExport
          printOptions={{ disableToolbarButton: true }}
          excelOptions={excelOptions}
        />
      )}
    </GridToolbarContainer>
  );
}

const AllTable = ({ clickOrders }) => {
  const isLoading = useSelector(isLoadingSelector);
  const error = useSelector(errorSelector);
  const data = useSelector(allSelector);
  const apiRef = useGridApiRef();
  const isDesktop = window.screen.width > 480;
  const theme = useTheme();

  const [sortModel, setSortModel] = useState([
    { field: 'total_orders', sort: 'desc' },
  ]);

  const [filterKey, setFilterKey] = useState('');
  const filteredData = useMemo(() => {
    if (!data) return [];
    const str = filterKey && filterKey.toLowerCase();
    const filteredPaths = new Set();
    const finalData = [];
    data.forEach(([path, details], index) => {
      const { breakdowns, total } = details;
      const breakdownsToAdd = [];
      // Paths
      const pathId = _.uniqueId('path-') + `-${index}`;
      let breakdownEntries = Object.entries(breakdowns) || [];
      const pathItem = {
        id: pathId,
        type: 'Path',
        name: path || '',
        total_orders: total?.total_orders,
        total_cv: total?.total_cv,
        nc_orders: total?.nc_orders,
        nc_cv: total?.nc_cv,
        rc_orders: total?.rc_orders,
        rc_cv: total?.rc_cv,
        nc_percent: total?.nc_percent,
        roas: total?.roas,
        nc_roas: total?.nc_roas,
        platform_roas: total?.platform_roas,
        platform_cv: total?.platform_cv,
        platform_spend: total?.platform_spend,
        non_pvm_roas: total?.non_pvm_roas,
        non_pvm_nc_roas: total?.non_pvm_nc_roas,
        onsite_purchases: total?.onsite_purchases,
        onsite_sales: total?.onsite_sales,
        nc_onsite_purchases: total?.nc_onsite_purchases,
        nc_onsite_sales: total?.nc_onsite_sales,
        hierarchy: [pathId],
        descendant_count: breakdownEntries?.length || 0,
        hidden_breakdown: 'TOTAL',
      };
      let includePath = !str || path.toLowerCase().includes(str);

      // Breakdowns
      breakdownEntries.forEach(
        ([breakdownName, breakdownData], breakdownIndex) => {
          const breakdownId = _.uniqueId('breakdown-') + `-${breakdownIndex}`;
          let includeBreakdown =
            includePath || !str || breakdownName.toLowerCase().includes(str);
          const breakdownItem = {
            id: breakdownId,
            type: 'Breakdown',
            total_orders: breakdownData?.total_orders,
            total_cv: breakdownData?.total_cv,
            nc_orders: breakdownData?.nc_orders,
            nc_cv: breakdownData?.nc_cv,
            rc_orders: breakdownData?.rc_orders,
            rc_cv: breakdownData?.rc_cv,
            nc_percent: breakdownData?.nc_percent,
            roas: breakdownData?.roas,
            nc_roas: breakdownData?.nc_roas,
            platform_roas: breakdownData?.platform_roas,
            platform_cv: breakdownData?.platform_cv,
            platform_spend: breakdownData?.platform_spend,
            non_pvm_roas: breakdownData?.non_pvm_roas,
            non_pvm_nc_roas: breakdownData?.non_pvm_nc_roas,
            onsite_purchases: breakdownData?.onsite_purchases,
            onsite_sales: breakdownData?.onsite_sales,
            nc_onsite_purchases: breakdownData?.nc_onsite_purchases,
            nc_onsite_sales: breakdownData?.nc_onsite_sales,
            descendant_count: 0,
            hierarchy: [pathId, breakdownId],
            hidden_breakdown: breakdownName || '',
            name: path || '',
          };

          if (includeBreakdown) {
            includePath = true;
            filteredPaths.add(pathId);
            breakdownsToAdd.push(breakdownItem);
          }
        }
      );

      if (includePath) {
        finalData.push(pathItem, ...breakdownsToAdd);
      }
    });
    return finalData;
  }, [filterKey, data]);

  const columnGroupingModel = [
    {
      groupId: 'pinned',
      headerName: '',
      headerAlign: 'center',
      children: [
        { field: 'id' },
        { field: 'name' },
        { field: 'hidden_breakdown' },
      ],
      cellClassName: 'border-right custom-border-right',
      headerClassName: 'border-right custom-border-right',
    },
    {
      groupId: 'PIXEL_ATTRIBUTED',
      headerName: 'Kendall Click Attributed',
      headerAlign: 'center',
      children: [
        { field: 'nc_percent' },
        { field: 'total_orders' },
        { field: 'total_cv' },
        { field: 'nc_orders' },
        { field: 'nc_cv' },
        { field: 'rc_orders' },
        { field: 'rc_cv' },
        { field: 'roas' },
        { field: 'nc_roas' },
      ],
      headerClassName: 'border-right paid-ads-table',
    },
    {
      groupId: 'PLATFORM_REPORTED',
      headerName: 'Platform Reported',
      headerAlign: 'center',
      children: [
        { field: 'platform_roas' },
        { field: 'platform_cv' },
        { field: 'platform_spend' },
      ],
      headerClassName: 'paid-ads-table',
    },
  ];

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: 'ID',
        headerAlign: 'center',
        disableExport: true,
      },
      {
        field: 'name',
        headerName: 'Source / Campaign',
        headerAlign: 'center',
        rowSpan: 2,
        width: 275,
        sortable: false,
        renderHeader: () => <PlatformTH label="Source / Campaign" />,
        renderCell: (params) => <GroupingCellWithLazyLoading {...params} />,
      },
      {
        field: 'hidden_breakdown',
        headerName: '',
        headerAlign: 'left',
        headerClassName: 'hide-table-cell',
        cellClassName: 'hide-table-cell',
        width: 0.1,
        minWidth: 0.1,
        sortable: false,
        renderCell: (params) => params.value,
      },
      {
        field: 'total_orders',
        headerName: 'Orders',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        sortable: true,
        renderCell: (params) =>
          !params.id.includes('path') ? (
            <OptionalLink
              color="primary"
              onClick={() => {
                const metaOrders =
                  params?.row?.name === 'Facebook Ads' &&
                  params?.row?.onsite_purchases
                    ? {
                        purchases: params?.row?.onsite_purchases,
                        sales: params?.row?.onsite_sales || 0,
                      }
                    : {};
                clickOrders(
                  {
                    source: params.row.name,
                    [params.row.name === 'Organic' ? 'referrer' : 'campaign']:
                      params.row.type === 'Breakdown'
                        ? params.row.hidden_breakdown
                        : undefined,
                  },
                  metaOrders
                );
              }}
              value={fNumber(params.value)}
            />
          ) : (
            fNumber(params.value)
          ),
        valueGetter: ({ value }) => Number(value),
      },
      {
        field: 'total_cv',
        headerName: 'Sales',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params) => (!params.value ? '-' : fCurrency(params.value)),
        valueFormatter: (params) =>
          !params.value ? '-' : `${fCurrency(params.value)}`,
      },
      {
        field: 'nc_percent',
        headerName: 'New Customer Ratio',
        renderHeader: (params) => (
          <div
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              fontWeight: 700,
            }}
          >
            New Customer
            <br />
            Ratio
          </div>
        ),
        width: 110,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        headerClassName: 'line-height-reset',
        renderCell: (params) => {
          if (!params.value) return '-';
          const adjustedValue = Number(params.value) * 100;
          const formattedValue = fPercent(adjustedValue, '0%');
          if (adjustedValue >= 60) {
            return (
              <Chip
                label={formattedValue}
                sx={{
                  color: '#fff',
                  backgroundColor: 'green',
                  height: 24,
                  fontSize: '0.7125rem',
                }}
              />
            );
          }
          return formattedValue;
        },
        valueFormatter: (params) =>
          !params.value ? '-' : `${fPercent(Number(params.value) * 100)}`,
      },
      {
        field: 'nc_orders',
        headerName: 'NC-Orders',
        headerAlign: 'center',
        align: 'center',
        type: 'number',
        sortable: true,
        renderCell: (params) =>
          !params.id.includes('path') ? (
            <OptionalLink
              color="primary"
              onClick={() => {
                const metaOrders =
                  params?.row?.name === 'Facebook Ads' &&
                  params?.row?.onsite_purchases
                    ? {
                        purchases: params?.row?.onsite_purchases,
                        sales: params?.row?.onsite_sales || 0,
                      }
                    : {};
                clickOrders(
                  {
                    source: params.row.name,
                    [params.row.name === 'Organic' ? 'referrer' : 'campaign']:
                      params.row.type === 'Breakdown'
                        ? params.row.hidden_breakdown
                        : undefined,
                    customer_type: 'new',
                  },
                  metaOrders
                );
              }}
              value={fNumber(params.value)}
            />
          ) : (
            fNumber(params.value)
          ),
      },
      {
        field: 'nc_cv',
        headerName: 'NC-Sales',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params) => (!params.value ? '-' : fCurrency(params.value)),
        valueFormatter: (params) =>
          !params.value ? '-' : `${fCurrency(params.value)}`,
      },
      {
        field: 'roas',
        headerName: 'ROAS',
        headerAlign: 'center',
        width: 80,
        align: 'center',
        sortable: false,
        renderCell: (params) =>
          !params.value ? '-' : formatToFixed(Number(params.value)),
        valueFormatter: (params) =>
          !params.value ? '-' : `${formatToFixed(Number(params.value))}`,
      },
      {
        field: 'nc_roas',
        headerName: 'NC-ROAS',
        headerAlign: 'center',
        width: 80,
        align: 'center',
        sortable: false,
        cellClassName: 'border-right custom-border-right',
        headerClassName: 'border-right custom-border-right',
        renderCell: (params) =>
          !params.value ? '-' : formatToFixed(Number(params.value)),
        valueFormatter: (params) =>
          !params.value ? '-' : `${formatToFixed(Number(params.value))}`,
      },
      {
        field: 'platform_spend',
        headerName: 'Spend',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        // cellClassName: 'border-right custom-border-right',
        // headerClassName: 'border-right custom-border-right',
        renderCell: (params) => (!params.value ? '-' : fCurrency(params.value)),
        valueFormatter: (params) =>
          !params.value ? '-' : `${fCurrency(params.value)}`,
      },
      {
        field: 'platform_roas',
        headerName: 'ROAS',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params) =>
          !params.value ? '-' : formatToFixed(Number(params.value)),
        valueFormatter: (params) =>
          !params.value ? '-' : `${formatToFixed(Number(params.value))}`,
      },
      {
        field: 'platform_cv',
        headerName: 'Sales',
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: (params) => (!params.value ? '-' : fCurrency(params.value)),
        valueFormatter: (params) =>
          !params.value ? '-' : `${fCurrency(params.value)}`,
      },
    ],
    [clickOrders]
  );

  let tableHeight = 'auto';
  const pinnedColumns = isDesktop
    ? {
        left:
          columnGroupingModel
            .find((gm) => gm.groupId === 'pinned')
            ?.children?.map((c) => c.field) || [],
      }
    : null;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'left',
          mb: 3,
          [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap',
            '.filter-search': {
              width: '100%',
              marginTop: '10px',
              marginLeft: '0 !important',
            },
            '.custom-filter-group': {
              gap: '4px',
            },
            '.custom-filter-item': {
              marginLeft: '0 !important',
            },
          },
        }}
      >
        <Filters visibleModel visibleWindow />
      </Box>
      <Card>
        <BoxDataGridStyleV2>
          <DataGridPremiumCustom
            treeData
            error={error}
            apiRef={apiRef}
            loading={isLoading}
            rows={filteredData || []}
            pagination={true}
            columns={columns || []}
            experimentalFeatures={{ columnGrouping: true }}
            columnGroupingModel={columnGroupingModel.filter(
              (group) => group.headerName.trim() !== ''
            )}
            sortModel={sortModel}
            onSortModelChange={(model) => setSortModel(model)}
            disableRowSelection={true}
            disableColumnMenu={true}
            getRowId={getRowId}
            getTreeDataPath={getTreeDataPath}
            slots={{
              toolbar: () => <CustomExportToolbar loading={isLoading} />,
            }}
            sx={{
              ...gridTableBorderStyle,
              '.hide-table-cell, .MuiDataGrid-columnHeader[data-fields="|-name-|-hidden_breakdown-|"]':
                {
                  display: 'none',
                },
              '.MuiDataGrid-columnHeaderTitleContainer': {
                whiteSpace: 'initial',
                wordWrap: 'break-word',
                '.MuiDataGrid-iconButtonContainer': {
                  'button.MuiButtonBase-root': {
                    position: 'absolute',
                    right: '-2px',
                    top: '20px',
                    padding: '3px',
                  },
                },
              },
              '.MuiDataGrid-pinnedColumnHeaders': {
                backgroundColor: '#ffffff',
              },
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 25 } },
              pinnedColumns: pinnedColumns,
              columns: {
                columnVisibilityModel: {
                  id: false,
                  __tree_data_group__: false,
                },
              },
            }}
            rowHeight={60} // initial row height (doesn't respect if the non-pinned row height is more than the value)
            getRowHeight={(params) => 'auto'} // auto calculate the row height by setting it to auto
          />
        </BoxDataGridStyleV2>
      </Card>
    </>
  );
};

export default memo(AllTable);
