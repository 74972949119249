import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// @mui
import { List, Collapse } from '@mui/material';
//
import { NavItemRoot, NavItemSub } from './NavItem';
import { getActive } from '..';
import uuidv4 from '@/utils/uuidv4';
import useAuth from '@/hooks/useAuth';
import { PATH_DASHBOARD } from '@/routes/paths';

// hooks
import useCollapseDrawer from '@/hooks/useCollapseDrawer';
import { actions } from '@/redux/slices/navbar';

// ----------------------------------------------------------------------

NavListRoot.propTypes = {
  isCollapse: PropTypes.bool,
  list: PropTypes.object,
};

export function NavListRoot({ list, isCollapse, isDisable = false }) {
  const { pathname } = useLocation();
  const { isDemoAccount } = useAuth();
  const dispatch = useDispatch();

  const { isDisableMenu } = useCollapseDrawer();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(
    typeof list.collapsed === 'boolean' ? !list.collapsed : true
  );

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={list}
          isDisable={isDisableMenu || isDisable}
          isCollapse={isCollapse}
          active={active}
          open={!list.collapsed}
          onOpen={() => {
            setOpen(!open);
            dispatch(
              actions.setCollapsed({
                title: list.title,
                collapsed: !list.collapsed,
              })
            );
          }}
        />

        {!isCollapse && (
          <Collapse in={!list.collapsed} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {(list.children || []).map((item) => {
                let isDisable = false;

                if (
                  item.path === PATH_DASHBOARD.main.integrations &&
                  isDemoAccount
                ) {
                  isDisable = true;
                }

                return (
                  <NavListSub
                    key={uuidv4()}
                    list={item}
                    isDisable={isDisable || item.disabled}
                  />
                );
              })}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return (
    <NavItemRoot
      isDisable={isDisableMenu || isDisable || list.disabled}
      item={list}
      active={active}
      isCollapse={isCollapse}
    />
  );
}

// ----------------------------------------------------------------------

NavListSub.propTypes = {
  list: PropTypes.object,
  isDisable: PropTypes.bool,
};

function NavListSub({ list, isDisable }) {
  const { pathname } = useLocation();
  const { isDemoAccount } = useAuth();

  const active = getActive(list.path, pathname);

  const [open, setOpen] = useState(active);

  const hasChildren = list.children;

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          item={list}
          onOpen={() => setOpen(!open)}
          open={open}
          active={active}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => {
              let isDisable = false;

              if (
                item.path === PATH_DASHBOARD.main.integrations &&
                isDemoAccount
              ) {
                isDisable = true;
              }

              return (
                <NavItemSub
                  key={uuidv4()}
                  item={item}
                  active={getActive(item.path, pathname)}
                  isDisable={isDisable}
                />
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return <NavItemSub item={list} active={active} isDisable={isDisable} />;
}
