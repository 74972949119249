import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from '@/utils/axios';
import { getSessionWithKey } from '@/utils/shopify';
import { trackPageAnalytics } from '@/utils/juneUtil';

const firstDay = new Date();
firstDay.setMonth(firstDay.getMonth() - 3);
const initialState = {
  filters: {
    filter_text: '',
    view_by_option: 'product', // or variant
    date: {
      startDate: firstDay,
      endDate: new Date(),
    },
  },
  isLoading: true,
  isCustomerBehaviourLoading: true,
  error: null,
  data: null,
  customerBehaviourData: null,
};

const slice = createSlice({
  name: 'productAnalytics',
  initialState,
  reducers: {
    setFilter(state, action) {
      state.filters[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProductAnalytics.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.requestId = action.meta.requestId;
      })
      .addCase(getProductAnalytics.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getProductAnalytics.rejected, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isLoading = false;
        state.data = null;
        state.error = action.error;
      })

      .addCase(getProductCustomerBehaviour.pending, (state, action) => {
        state.isCustomerBehaviourLoading = true;
        state.error = null;
        state.requestId = action.meta.requestId;
        state.customerBehaviourData = null;
      })
      .addCase(getProductCustomerBehaviour.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isCustomerBehaviourLoading = false;
        state.customerBehaviourData = action.payload;
      })
      .addCase(getProductCustomerBehaviour.rejected, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isCustomerBehaviourLoading = false;
        state.customerBehaviourData = null;
        state.error = action.error;
      });
  },
});

export default slice.reducer;

export const getProductAnalytics = createAsyncThunk(
  'productAnalytics/getProductAnalytics',
  async ({ type, ...params }, thunkAPI) => {
    trackPageAnalytics(params);

    const response = await axios.get('product-analytics', {
      params: {
        ...params,
        secret: getSessionWithKey('secret'),
      },
    });

    return response.data;
  }
);

export const getProductCustomerBehaviour = createAsyncThunk(
  'productAnalytics/getProductCustomerBehaviour',
  async ({ type, ...params }, thunkAPI) => {
    trackPageAnalytics(params);

    const response = await axios.get('product-customer-behavior', {
      params: {
        ...params,
        secret: getSessionWithKey('secret'),
      },
    });

    return response.data;
  }
);

// selectors

const selectAllSources = (state) => state.allSources;

export const filtersSelector = createSelector(
  selectAllSources,
  (state) => state.filters
);

export const isLoadingSelector = createSelector(
  selectAllSources,
  (state) => state.isLoading
);

export const errorSelector = createSelector(
  selectAllSources,
  (state) => state.error
);

export const allSelector = createSelector(selectAllSources, (state) =>
  state.data ? Object.entries(state.data) : null
);

export const { setFilter } = slice.actions;
