import useAuth from '@/hooks/useAuth';
import React, { useEffect } from 'react';

function Inspectlet(props) {
  const { isDemoAccount, user, isFetchedStoreInfo } = useAuth();
  let shouldExecute = isDemoAccount || (isFetchedStoreInfo && !user?.active);

  // should run for the demo only
  useEffect(() => {
    if (!shouldExecute) return;

    (function () {
      window.__insp = window.__insp || [];
      window.__insp.push(['wid', 1141825102]);
      window.__insp.push([
        'crossDomain',
        [
          'kendall.ai',
          'app-staging.kendall.ai',
          'app-dev.kendall.ai',
          'kendall-ui-git-inspectlet-kendall.vercel.app',
        ],
      ]);
      var ldinsp = function () {
        if (typeof window.__inspld != 'undefined') return;
        window.__inspld = 1;
        var insp = document.createElement('script');
        insp.type = 'text/javascript';
        insp.async = true;
        insp.id = 'inspsync';
        insp.src =
          ('https:' == document.location.protocol ? 'https' : 'http') +
          '://cdn.inspectlet.com/inspectlet.js?wid=1141825102&r=' +
          Math.floor(new Date().getTime() / 3600000);
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(insp, x);
      };
      setTimeout(ldinsp, 0);
    })();
  }, [shouldExecute]);

  return <div></div>;
}

export default Inspectlet;
