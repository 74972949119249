import React from 'react';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ErrorImg from '@/assets/images/error-7-512.png';

const ErrorIcon = styled(m.img)(({ theme }) => ({
  marginBottom: '5px',
  width: '50px',
  height: '50px',
}));

const ErrorLoadingData = ({ message = 'Error loading data', ...props }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      }}
      {...props}
    >
      <ErrorIcon src={ErrorImg} />
      <Typography>{message}</Typography>
    </Box>
  );
};

export default ErrorLoadingData;
