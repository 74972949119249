import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '@/utils/axios';
import { dispatch } from '../store';
import { onError } from './notification';
import { getSessionWithKey } from '@/utils/shopify';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const slice = createSlice({
  name: 'churn',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA
    getChurnCohortSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, cohort: { ...action.payload } };
    },

    getChurnCohortSelectValuesSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, selectValues: { ...action.payload } };
    },

    getChurnActiveCustomersSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, activeCustomers: { ...action.payload } };
    },

    getChurnMonthlyTrendsSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, monthlyTrends: { ...action.payload } };
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getChurnCohort(params) {
  const secret = getSessionWithKey('secret');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_cohortchurn', {
        params: {
          ...params,
          secret,
        },
      });

      dispatch(slice.actions.getChurnCohortSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function getChurnCohortSelectValues(params) {
  let { store_id, element } = params;
  const secret = getSessionWithKey('secret');

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_data', {
        params: {
          store_id,
          element,
          secret,
        },
      });

      dispatch(slice.actions.getChurnCohortSelectValuesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function getChurnActiveCustomers(params) {
  let { store_id, element } = params;
  const secret = getSessionWithKey('secret');

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_data', {
        params: {
          store_id,
          element,
          secret,
        },
      });

      dispatch(slice.actions.getChurnActiveCustomersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function getChurnMonthlyTrends(params) {
  let { store_id, element } = params;
  const secret = getSessionWithKey('secret');

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_data', {
        params: {
          store_id,
          element,
          secret,
        },
      });

      dispatch(slice.actions.getChurnMonthlyTrendsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}
