import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '@/utils/axios';
import { getSessionWithKey } from '@/utils/shopify';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  error: null,
  data: {},
};

const slice = createSlice({
  name: 'adsOrderDetails',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAdsOrderDetails.pending, (state) => {
        state.isLoading = true;
        state.data = {};
        state.error = null;
      })
      .addCase(getAdsOrderDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getAdsOrderDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export const getAdsOrderDetails = createAsyncThunk(
  'adsOrderDetails/getAdsOrderDetails',
  async (params) => {
    const response = await axios.get('/fetch_orderdetails', {
      params: {
        ...params,
        secret: getSessionWithKey('secret'),
      },
    });
    return response.data;
  }
);
