import { Fragment, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/system';
import { Box, Link, Pagination } from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { flatten } from 'lodash';

import { ErrorLoadingData } from '@/components/errors';
import Spinner from '@/components/Spinner';

export const OptionalLink = (props) => {
  const { value } = props;
  if (value === 0 || value === '0') {
    return '-';
  }
  return <Link {...props}>{value}</Link>;
};

export const convNum = (v) => Number(v) || 0;

const StyledTable = styled('table')({
  width: '100%',
  borderSpacing: 0,
  backgroundColor: '#fff',
  borderTop: '1px solid #EAECF0',
  borderLeft: '1px solid #EAECF0',

  thead: {
    zIndex: 1,
    position: 'sticky',
    top: 45,
    backgroundColor: '#fff',
    color: '#6F7B90',
    th: {
      whiteSpace: 'nowrap',
      fontWeight: 700,
      color: '#1c1e21',
    },
  },

  tbody: {
    color: '#1c1e21',

    tr: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#FAFAFB',
      },
    },

    a: {
      color: '#1461cc',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },

  'th, td': {
    padding: '10px 16px',
    fontSize: 12,
    fontWeight: 500,
    borderBottom: '1px solid #EAECF0',
    borderRight: '1px solid #EAECF0',
  },

  th: {
    '& > div': {
      display: 'inline-flex',
      alignItems: 'center',
      '.sort-icon': {
        opacity: 0.75,
        fontSize: 16,
      },
    },
    '&:not(.sorted)': {
      '& .sort-icon': {
        opacity: 0,
        transition: 'opacity 0.3s',
      },
      '&:hover': {
        '& .sort-icon': {
          opacity: 0.35,
        },
      },
    },
  },
});

const Loading = styled('div')({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  width: '100%',
  height: '100%',
  paddingTop: 78,
  background: 'rgba(255, 255, 255, 0.5)',
  borderRadius: 10,
  overflow: 'hidden',
  zIndex: 1,
});

export const getSortedData = (data, sortKey, isDesc, columns) => {
  if (!data || !sortKey) return data;
  let sorter = columns.find(({ key }) => key === sortKey)?.sorter;
  if (typeof sorter !== 'function') {
    sorter = (a, b) => {
      const a1 = a[sortKey];
      const b1 = b[sortKey];
      return a1 > b1 ? 1 : a1 < b1 ? -1 : 0;
    };
  }
  return data.slice().sort((a, b) => sorter(a, b) * (isDesc ? 1 : -1));
};

const Row = ({ columns, rowData }) => (
  <tr>
    {columns.map((c) => (
      <td
        key={c.key}
        style={{
          textAlign: c.align || 'center',
          ...c.style,
          ...c.bodyStyle,
        }}
      >
        {c.renderer ? c.renderer(rowData[c.key], rowData) : rowData[c.key]}
      </td>
    ))}
  </tr>
);

const Table = ({
  columns,
  data,
  getSubData,
  isLoading,
  error,
  pageSize = 25,
}) => {
  const dataColumns = useMemo(
    () => flatten(columns).filter((info) => !info.isGroup),
    [columns]
  );

  const [sortKey, setSortKey] = useState();
  const [isDesc, setIsDesc] = useState(false);

  const pageCount = useMemo(
    () => Math.ceil(data?.length / pageSize) || 0,
    [data?.length, pageSize]
  );
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentPage(1);
  }, [data]);

  const sortedData = useMemo(
    () => getSortedData(data, sortKey, isDesc, dataColumns),
    [data, sortKey, isDesc, dataColumns]
  );

  const pageData = useMemo(
    () =>
      sortedData?.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    [sortedData, currentPage, pageSize]
  );

  return (
    <>
      <div style={{ position: isLoading ? 'relative' : 'initial' }}>
        <StyledTable>
          <thead>
            {columns.map((hColumns, i) => (
              <tr key={i}>
                {hColumns.map((c, j) => (
                  <th
                    key={j}
                    rowSpan={c.rowSpan}
                    colSpan={c.colSpan}
                    width={c.width}
                    style={{
                      textAlign: c.align || 'center',
                      ...c.style,
                      ...c.headerStyle,
                      cursor: c.sorter ? 'pointer' : 'initial',
                    }}
                    className={c.key === sortKey ? 'sorted' : ''}
                    onClick={
                      c.sorter
                        ? () => {
                            if (c.key !== sortKey) {
                              setSortKey(c.key);
                              setIsDesc(false);
                            } else if (isDesc) {
                              setSortKey();
                            } else {
                              setIsDesc(true);
                            }
                          }
                        : null
                    }
                  >
                    <div>
                      {c.label}
                      {c.sorter && (
                        <>
                          {c.key === sortKey && isDesc ? (
                            <ArrowUpwardIcon className="sort-icon" />
                          ) : (
                            <ArrowDownwardIcon className="sort-icon" />
                          )}
                        </>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody>
            {error || !pageData?.length ? (
              <tr>
                <td
                  colSpan={dataColumns.length}
                  style={{ backgroundColor: '#fff', padding: '32px 0' }}
                >
                  {error ? (
                    <ErrorLoadingData />
                  ) : (
                    <div style={{ padding: '40px 0' }} />
                  )}
                </td>
              </tr>
            ) : (
              pageData.map((item, i) => (
                <Fragment key={i}>
                  <Row columns={dataColumns} rowData={item} />
                  {getSubData?.(item, sortKey, isDesc, columns)?.map(
                    (item1, j) => (
                      <Row key={j} columns={dataColumns} rowData={item1} />
                    )
                  )}
                </Fragment>
              ))
            )}
          </tbody>
        </StyledTable>

        {isLoading && (
          <Loading>
            <Spinner />
          </Loading>
        )}
      </div>

      {pageCount > 1 && (
        <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={(e, page) => setCurrentPage(page)}
            color="primary"
          />
        </Box>
      )}
    </>
  );
};

export default Table;
