import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Link from '@mui/material/Link';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const FailedIntegrationsBanner = ({ failed_integrations }) => {
  const failed_integrations_temp = failed_integrations
    .slice()
    .map((obj) => obj.platform.charAt(0).toUpperCase() + obj.platform.slice(1));
  const last = failed_integrations_temp.pop();
  //console.log({ failed_integrations_temp, last });
  const failed_integrations_concat = failed_integrations_temp.length
    ? failed_integrations_temp.join(', ') + ' and ' + last
    : last;

  return (
    <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert severity="info">
        Your <span>{failed_integrations_concat}</span> integration
        {failed_integrations.length > 1 ? 's' : ''} has stopped working. Please
        <Link
          href="/dashboard/integrations"
          sx={{ color: '#000', cursor: 'pointer' }}
        >
          {` `}re-connect
        </Link>
        {` `}it.
      </Alert>
    </Snackbar>
  );
};

export default FailedIntegrationsBanner;
