import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const SpinnerV2 = () => {
  return (
    <div id="spinner-container">
      <Backdrop
        sx={{
          // color: '#fff',
          color: '#161c247a',
          // background: '#161c247a',
          background: 'none',
          // zIndex: (theme) => theme.zIndex.drawer + 1,
          zIndex: 100,
          transform: 'translateX(80px)',
        }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default SpinnerV2;
