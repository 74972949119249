import { createSlice } from '@reduxjs/toolkit';
// utils
import { dispatch } from '../store';
// ----------------------------------------------------------------------

const initialState = {
  notifications: [],
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showNotification(state, action) {
      state.notifications = [...state.notifications, action.payload];
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function onError(message = 'Something went wrong!') {
  return () => {
    dispatch(slice.actions.showNotification({ type: 'error', message }));
  };
}

export function onSuccess(message = 'Success!') {
  return () => {
    dispatch(slice.actions.showNotification({ type: 'success', message }));
  };
}
