import { createSlice, createSelector } from '@reduxjs/toolkit';
import axios from '@/utils/axios';
import { dispatch } from '../store';
import { getSessionWithKey } from '@/utils/shopify';

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

const slice = createSlice({
  name: 'profitAndLoss',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getProfitAndLossSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },
  },
});

export const { startLoading, hasError, getProfitAndLossSuccess } =
  slice.actions;
export default slice.reducer;

export function fetchProfitAndLossData(params) {
  return async () => {
    dispatch(startLoading());
    try {
      const secret = getSessionWithKey('secret');
      const response = await axios.get('/fetch_kpi', {
        params: { ...params, secret },
      });

      dispatch(getProfitAndLossSuccess(response.data));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
}

export const selectProfitAndLoss = (state) => state.ProfitAndLoss;
export const profitAndLossSelector = createSelector(
  selectProfitAndLoss,
  (state) => state.data
);
export const isLoadingSelector = createSelector(
  selectProfitAndLoss,
  (state) => state.isLoading
);
export const errorSelector = createSelector(
  selectProfitAndLoss,
  (state) => state.error
);
