import React, { memo, useMemo, useState } from 'react';
import {
  format,
  subDays,
  startOfDay,
  startOfWeek,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfQuarter,
  endOfQuarter,
  startOfYear,
  endOfYear,
  startOfYesterday,
  endOfYesterday,
  startOfToday,
  min,
  max,
  isBefore,
  isEqual,
  endOfToday,
} from 'date-fns';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import EventIcon from '@mui/icons-material/Event';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { DateRangePicker as MUIDateRangePicker } from '@matharumanpreet00/react-daterange-picker';
import {
  CUSTOM_RANGE_DATE_PICKER_TIMEZONED,
  getZonedEndOfToday,
} from './customDateWithTimezone';

export const CUSTOM_RANGE_DATE_PICKER = [
  {
    label: 'Today',
    startDate: () => startOfToday(),
  },
  {
    label: 'Yesterday',
    startDate: () => startOfYesterday(),
    endDate: () => endOfYesterday(),
  },
  {
    label: 'Last 7 days',
    startDate: (today) => startOfDay(subDays(today, 7)),
    endDate: () => endOfYesterday(),
  },
  {
    label: 'Last 14 days',
    startDate: (today) => startOfDay(subDays(today, 14)),
    endDate: () => endOfYesterday(),
  },
  {
    label: 'Last 30 days',
    startDate: (today) => startOfDay(subDays(today, 30)),
    endDate: () => endOfYesterday(),
  },
  {
    label: 'Last 90 days',
    startDate: (today) => startOfDay(subDays(today, 90)),
    endDate: () => endOfYesterday(),
  },
  {
    label: 'Last 365 days',
    startDate: (today) => startOfDay(subDays(today, 365)),
    endDate: () => endOfYesterday(),
  },
  {
    label: 'Last Month',
    startDate: (today) => startOfMonth(subMonths(today, 1)),
    endDate: (today) => endOfMonth(subMonths(today, 1)),
  },
  {
    label: 'Last Quarter',
    startDate: (today) => startOfQuarter(subMonths(today, 3)),
    endDate: (today) => endOfQuarter(subMonths(today, 3)),
  },
  {
    label: 'Last Year',
    startDate: (today) => startOfYear(subMonths(today, 12)),
    endDate: (today) => endOfYear(subMonths(today, 12)),
  },
  {
    label: 'This Week',
    startDate: (today) => startOfWeek(today, { weekStartsOn: 1 }),
  },
  {
    label: 'This Month',
    startDate: (today) => startOfMonth(today),
  },
  {
    label: 'This Quarter',
    startDate: (today) => startOfQuarter(today),
  },
  {
    label: 'This Year',
    startDate: (today) => startOfYear(today),
  },
];

const RootStyle = styled(Box)({
  position: 'relative',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#fff',

  gap: '5px',

  '.MuiTextField-root': {
    maxWidth: '120px',
  },
});

const Sizes = {
  small: {
    fontSize: '0.875rem',
    padding: '3px 2px 3px 10px',
  },
  medium: {
    fontSize: '0.875rem',
    padding: '5px 3px 5px 15px',
  },
  large: {
    fontSize: '0.9375rem',
    padding: '7px 4px 7px 21px',
  },
};

const DatePickerButton = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  borderRadius: '8px',
  boxSizing: 'content-box',
  cursor: 'pointer',
  lineHeight: 1,

  '&:hover': {
    boxShadow: '0 0 0 1px #212B36',
  },

  '.calendar-icon': {
    fontSize: '1.125em',
    color: '#212B36',
    marginRight: '0.125em',
  },

  span: {
    margin: '0 0.125em',
  },
});

const MUIDateRangePickerStyle = styled(Box)({
  position: 'absolute',
  top: '100%',
  left: 0,
  marginTop: '2px',
  zIndex: 99,

  '.muiCalendarMask': {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
  },

  '& > div > div': {
    flexDirection: 'row-reverse',
    '& > div:nth-of-type(3) > ul > div > div': {
      paddingRight: 0,
      whiteSpace: 'nowrap',
    },
  },
});

const DateRangePicker = ({
  value,
  onChange,
  dateFormat = 'MM/dd/yyyy',
  maxDate,
  minDate = null,
  size,
  sx,
  timezone = '',
  disabled = false,
  ...rest
}) => {
  const [isShowDatePicker, setIsShowDatePicker] = useState(false);
  const strDate = useMemo(() => {
    let strStartDate = format(value.startDate, dateFormat);
    let strEndDate = format(value.endDate, dateFormat);
    if (strStartDate !== strEndDate) {
      return `${strStartDate} - ${strEndDate}`;
    }
    if (strStartDate === format(startOfToday(), dateFormat)) {
      return 'Today';
    }
    if (strStartDate === format(startOfYesterday(), dateFormat)) {
      return 'Yesterday';
    }
    return strStartDate;
  }, [value, dateFormat]);

  const definedRanges = useMemo(() => {
    const today = new Date();
    const _maxDate = maxDate || getZonedEndOfToday(timezone); // maxDate is expected to be timezoned
    const _minDate = minDate ? new Date(minDate) : null;
    const UPDATED_CUSTOM_RANGE_DATE_PICKER = Array.isArray(
      rest.date_range_exclude
    )
      ? CUSTOM_RANGE_DATE_PICKER_TIMEZONED(timezone).filter(
          (item) => !rest.date_range_exclude.includes(item.label)
        )
      : CUSTOM_RANGE_DATE_PICKER_TIMEZONED(timezone);

    return UPDATED_CUSTOM_RANGE_DATE_PICKER.map((item) => ({
      label: item.label,
      startDate: _minDate
        ? max([item.startDate(today), _minDate])
        : min([item.startDate(today), _maxDate]),
      endDate: min([item.endDate?.(today) || today, _maxDate]),
    })).filter(({ startDate, endDate, label }) => {
      return isBefore(startDate, endDate) || isEqual(startDate, endDate);
    });
  }, [maxDate, timezone, minDate]);

  const handleChange = ({ startDate, endDate, label }) => {
    onChange({
      startDate,
      endDate,
      label,
    });
    handleHideDatePicker();
  };

  const handleShowDatePicker = () => {
    if (!disabled) {
      setIsShowDatePicker(true);
    }
  };
  const handleHideDatePicker = () => setIsShowDatePicker(false);

  return (
    <RootStyle sx={sx}>
      <DatePickerButton
        sx={{
          boxShadow: isShowDatePicker
            ? '0 0 0 2px #15489a'
            : '0 0 0 1px rgba(145, 158, 171, 0.32)',
          ...(Sizes[size] || Sizes.medium),
          cursor: disabled ? 'not-allowed' : 'pointer',
          opacity: disabled ? 0.7 : 1,
        }}
        onClick={handleShowDatePicker}
      >
        <EventIcon className="calendar-icon" />
        <span>{strDate}</span>
        {!disabled && isShowDatePicker ? (
          <ArrowDropUpIcon />
        ) : (
          <ArrowDropDownIcon />
        )}
      </DatePickerButton>

      <MUIDateRangePickerStyle>
        <MUIDateRangePicker
          open={isShowDatePicker}
          initialDateRange={value}
          toggle={() => setIsShowDatePicker(!isShowDatePicker)}
          onChange={handleChange}
          definedRanges={definedRanges}
          maxDate={maxDate || endOfToday()}
          minDate={minDate}
          {...rest}
        />

        {isShowDatePicker && (
          <Box className="muiCalendarMask" onClick={handleHideDatePicker}></Box>
        )}
      </MUIDateRangePickerStyle>
    </RootStyle>
  );
};

export default memo(DateRangePicker);
