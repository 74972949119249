import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useSettings from '@/hooks/useSettings';
import useResponsive from '@/hooks/useResponsive';
import useCollapseDrawer from '@/hooks/useCollapseDrawer';
// config
import { HEADER, NAVBAR } from '@/config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import Notification from '@/components/Notification';
import useAuth from '@/hooks/useAuth';
import { useDispatch, useSelector } from '@/redux/store';
import { getIntegrations } from '@/redux/slices/user';
import ConstructionPage from '@/pages/dashboard/ConstructionPage';
import Logo from '@/components/Logo';
import NavbarAccount from '@/layouts/dashboard/navbar/NavbarAccount';
import { SETTINGS_PAGE_PATHS } from '@/config';
import { PATH_DASHBOARD } from '@/routes/paths';
import { isStateBetweenZeroAndTwo } from '@/utils/onboardingStates';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, ismobile, theme }) => ({
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  width: `calc(100% - ${ismobile === 'true' ? 0 : NAVBAR.DASHBOARD_WIDTH}px)`,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${ismobile === 'true' ? 0 : NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { collapseClick, disableMenu } = useCollapseDrawer();
  const dispatch = useDispatch();

  const { themeLayout } = useSettings();
  const {
    isAuthenticated,
    isInitialized,
    isDemoAccount,
    isFetchedStoreInfo,
    fetchStoreInfo,
    user,
  } = useAuth();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';
  let isConstruction =
    user && typeof user.active !== 'undefined' && !user.active;

  if (user?.internalUser) isConstruction = false;

  // override construction for pages under the settings menu
  if (SETTINGS_PAGE_PATHS.includes(window?.location?.pathname || '')) {
    isConstruction = false; // Should NOT show the “We’re preparing your Data” page even if active for the store info is false/null.
  }

  const integrations = useSelector((state) => state.user.integrations);

  useEffect(() => {
    if (
      isAuthenticated &&
      isInitialized &&
      user.store_id &&
      !isDemoAccount &&
      !isFetchedStoreInfo
    ) {
      fetchStoreInfo(user.store_id);
      dispatch(
        getIntegrations({
          store_id: user.store_id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isInitialized, isDemoAccount]);

  const navigate = useNavigate();
  //console.log('isDemoAccount', isDemoAccount);
  if (!isDemoAccount) {
    const isUserOnboarding = isStateBetweenZeroAndTwo(
      user?.active,
      integrations
    );

    //console.log('isUserOnboarding', isUserOnboarding);

    const SETTINGS_PATHS = [
      ...SETTINGS_PAGE_PATHS,
      PATH_DASHBOARD.main.storeSettings,
    ];

    if (
      isUserOnboarding &&
      !SETTINGS_PATHS.includes(window?.location?.pathname || '')
    ) {
      navigate(PATH_DASHBOARD.main.integrations);
    }
  }

  useEffect(() => {
    disableMenu(isConstruction);
  }, [disableMenu, isConstruction]);

  const renderContent = () => {
    if (isConstruction) {
      return (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <ConstructionPage />
        </Box>
      );
    }

    return <Outlet />;
  };

  if (verticalLayout) {
    return (
      <>
        <Notification />
        <DashboardHeader
          onOpenSidebar={() => setOpen(true)}
          verticalLayout={verticalLayout}
        />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
            onOpenSidebar={() => setOpen(true)}
          />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          {renderContent()}
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        '& .MuiSnackbar-root': {
          top: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 20}px`,
        },
      }}
    >
      <Notification />

      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 999,
          width: '100%',
          display: 'flex',
          px: '15px',
          maxHeight: '40px',
          justifyContent: 'space-between',
          alignItems: 'center',
          background: '#fff',
          borderBottom: '1px solid rgba(145, 158, 171, 0.3)',
          boxShadow: '2px 2px 5px rgb(145 158 171 / 30%)',
        }}
      >
        <Logo />
        <NavbarAccount isCollapse onOpenSidebar={() => setOpen(true)} />
      </Box>

      <Box
        className="main-container-inner"
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          minHeight: { lg: 1 },

          '& .MuiDrawer-paper': {
            top: '45px',
            height: 'calc(100% - 45px)',
          },
        }}
      >
        <NavbarVertical
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
          onOpenSidebar={() => setOpen(true)}
        />

        <MainStyle
          ismobile={!isDesktop ? 'true' : 'false'}
          collapseClick={collapseClick}
        >
          {renderContent()}
        </MainStyle>
      </Box>
    </Box>
  );
}
