import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

const Spinner = (props) => {
  return (
    <div className="lds-ring" {...props}>
      <CircularProgress />
    </div>
  );
};

export default Spinner;
