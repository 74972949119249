import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import axios from '@/utils/axios';
import { getSessionWithKey } from '@/utils/shopify';
import { trackPageAnalytics } from '@/utils/juneUtil';

const attributionTypeEndpoingMapping = {
  funnel: 'fetch_funnel',
  'marketting-efficiency': 'fetch_kpi',
  kpi: 'fetch_kpi',
  all: 'fetch_attribution',
};

// reducer

const initialState = {
  type: '',
  filters: {
    platform: 'facebook',
    dateType: 'event',
    model: 'any_click',
    customerType: 'new',
    window: '180',
    breakdown: 'ad',
    date: {
      startDate: new Date(),
      endDate: new Date(),
    },
  },

  requestId: '',
  isLoading: true,
  error: null,
  data: null,
};

const slice = createSlice({
  name: 'attribution',
  initialState,
  reducers: {
    setFilter(state, action) {
      state.filters[action.payload.key] = action.payload.value;
    },
    setType(state, action) {
      if (state.type !== action.payload) {
        state.data = null;
      }
      state.type = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttribution.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.requestId = action.meta.requestId;
      })
      .addCase(getAttribution.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getAttribution.rejected, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isLoading = false;
        state.data = null;
        state.error = action.error;
      });
  },
});

export default slice.reducer;

// actions

export const { setFilter, setType } = slice.actions;

export const getAttribution = createAsyncThunk(
  'attribution/getAttribution',
  async ({ type, ...params }, thunkAPI) => {
    thunkAPI.dispatch(setType(type));

    trackPageAnalytics(params);

    const response = await axios.get(
      attributionTypeEndpoingMapping[type] || 'fetch_attribution',
      {
        params: {
          ...params,
          secret: getSessionWithKey('secret'),
        },
      }
    );

    return response.data;
  }
);

// selectors

const selectAttribution = (state) => state.attribution;

export const filtersSelector = createSelector(
  selectAttribution,
  (state) => state.filters
);

export const isLoadingSelector = createSelector(
  selectAttribution,
  (state) => state.isLoading
);

export const errorSelector = createSelector(
  selectAttribution,
  (state) => state.error
);

export const allSelector = createSelector(selectAttribution, (state) =>
  state.type === 'all' ? state.data : null
);

export const funnelSelector = createSelector(selectAttribution, (state) =>
  state.type === 'funnel' ? state.data : null
);

export const paidAdsSelector = createSelector(selectAttribution, (state) =>
  state.type === 'paid-ads' && state.data
    ? Object.values(state.data.campaigns)
    : null
);

export const marketingEfficienctSelector = createSelector(
  selectAttribution,
  (state) =>
    state.type === 'marketting-efficiency' && state.data ? state.data : null
);

export const kpiSelector = createSelector(selectAttribution, (state) =>
  state.type === 'kpi' && state.data ? state.data : null
);
