import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

export const gridTableBorderColor = '#d6d6d6';
// export const gridTableBorderColor = 'rgba(241, 243, 244, 1)';

export const BoxDataGridStyle = styled(Box)({
  overflow: 'hidden',
  '.word-wrap': {
    wordBreak: 'break-word',
    whiteSpace: 'pre-line !important',
  },

  '.MuiDataGrid-virtualScroller': {
    overflow: 'hidden',
  },

  '.MuiDataGrid-toolbarContainer': {
    paddingLeft: '0 !important',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',

    '& > .MuiBox-root': {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 11,
    },
  },
  '.MuiDataGrid-columnHeaderTitleContainerContent': {
    fontSize: 11,
  },
  '& > .MuiBox-root': {
    overflow: 'auto',
    '& > .MuiDataGrid-root': {
      '&.min-1700': {
        minWidth: 1700,
      },
      '&.min-1400': {
        minWidth: 1400,
      },
      '&.min-1300': {
        minWidth: 1300,
      },

      '.MuiDataGrid-row': {
        maxHeight: 'inherit !important',
      },

      '.MuiDataGrid-cell': {
        fontSize: 12,
        paddingLeft: 7,
        paddingRight: 7,
        maxHeight: 'inherit !important',
        minHeight: '48px !important',
      },
      '.MuiDataGrid-columnHeader': {
        paddingLeft: 7,
        paddingRight: 7,
      },

      '.MuiDataGrid-columnHeadersInner': {},
    },
  },

  '.border-left': {
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 101,
      width: 2,
      height: '100%',
      backgroundColor: '#fff',
      transform: 'translateX(-50%)',
    },
  },

  '.border-right': {
    position: 'relative',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 101,
      width: 2,
      height: '100%',
      backgroundColor: '#fff',
      transform: 'translateX(50%)',
    },
  },

  '.color': {
    '&--0': {
      color: '#000',
      backgroundColor: '#f3fdf5',
    },
    '&--1': {
      color: '#000',
      backgroundColor: '#e8fbec',
    },
    '&--2': {
      color: '#000',
      backgroundColor: '#def9e4',
    },
    '&--3': {
      color: '#000',
      backgroundColor: '#d3f7db',
    },
    '&--4': {
      color: '#000',
      backgroundColor: '#caf5d4',
    },
    '&--5': {
      color: '#000',
      backgroundColor: '#c0f4cc',
    },
    '&--6': {
      color: '#000',
      backgroundColor: '#b9f3c6',
    },
    '&--7': {
      color: '#000',
      backgroundColor: '#b1f1bf',
    },
    '&--8': {
      color: '#000',
      backgroundColor: '#aef1bc',
    },
    '&--9': {
      color: '#000',
      backgroundColor: '#aff1bd',
    },
  },
});

export const BoxDataGridStyleV2 = styled(Box)(({ cellHeight }) => ({
  '[data-field="__tree_data_group__"], [data-fields="|-__tree_data_group__-|"]':
    {
      display: 'none !important',
    },
  '.line-height-reset': {
    lineHeight: '1.5',
  },
  '.fb-attr-col': {
    display: 'flex',
    flexDirection: 'column',
  },
  // overflow: 'hidden',
  '.word-wrap': {
    wordBreak: 'break-word',
    whiteSpace: 'pre-line !important',
  },

  '.MuiDataGrid-virtualScroller': {
    // overflow: 'hidden',
  },

  '.MuiDataGrid-toolbarContainer': {
    paddingLeft: '0 !important',
    paddingTop: '0 !important',
    paddingBottom: '0 !important',

    '& > .MuiBox-root': {
      paddingTop: 10,
      paddingBottom: 10,
      fontSize: 11,
    },
  },
  '.MuiDataGrid-columnHeaderTitleContainerContent': {
    fontSize: 11,
  },
  '& > .MuiBox-root': {
    // overflow: 'auto',
    '& > .MuiDataGrid-root': {
      '&.min-1700': {
        minWidth: 1700,
      },
      '&.min-1400': {
        minWidth: 1400,
      },
      '&.min-1300': {
        minWidth: 1300,
      },

      '.MuiDataGrid-row': {
        maxHeight: 'inherit !important',
      },

      '.MuiDataGrid-cell': {
        fontSize: 12,
        paddingLeft: 7,
        paddingRight: 7,
        maxHeight: 'inherit !important',
        minHeight: `${cellHeight || 50}px !important`,
      },
      '.MuiDataGrid-columnHeader': {
        paddingLeft: 7,
        paddingRight: 7,
      },

      '.MuiDataGrid-columnHeadersInner': {},
    },
  },

  '.border-left': {
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 101,
      width: 2,
      height: '100%',
      backgroundColor: '#fff',
      transform: 'translateX(-50%)',
    },
  },

  '.border-right': {
    position: 'relative',

    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: 101,
      width: 2,
      height: '100%',
      backgroundColor: '#fff',
      transform: 'translateX(50%)',
    },
  },
  '.paid-ads-table.border-right, .ads-creative-table.border-right, .custom-border-right.border-right':
    {
      '&::after': {
        width: 1,
        backgroundColor: gridTableBorderColor,
      },
    },
  '.paid-ads-table.border-left, .ads-creative-table.border-left, .custom-border-right.border-left':
    {
      '&::before': {
        width: 1,
        backgroundColor: gridTableBorderColor,
      },
    },
  '.custom-border-right.border-right': {
    '&::after': {
      transform: 'translateX(0%)',
    },
  },
  '.custom-border-right.border-left': {
    '&::before': {
      transform: 'translateX(0%)',
    },
  },
  '.kmv-header-cell': {
    backgroundColor: 'rgb(230, 255, 236)',
  },

  '.color': {
    '&--0': {
      color: '#000',
      backgroundColor: '#f3fdf5',
    },
    '&--1': {
      color: '#000',
      backgroundColor: '#e8fbec',
    },
    '&--2': {
      color: '#000',
      backgroundColor: '#def9e4',
    },
    '&--3': {
      color: '#000',
      backgroundColor: '#d3f7db',
    },
    '&--4': {
      color: '#000',
      backgroundColor: '#caf5d4',
    },
    '&--5': {
      color: '#000',
      backgroundColor: '#c0f4cc',
    },
    '&--6': {
      color: '#000',
      backgroundColor: '#b9f3c6',
    },
    '&--7': {
      color: '#000',
      backgroundColor: '#b1f1bf',
    },
    '&--8': {
      color: '#000',
      backgroundColor: '#aef1bc',
    },
    '&--9': {
      color: '#000',
      backgroundColor: '#aff1bd',
    },
  },
}));

export const gridTableBorderFontStyle = {
  '.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell, .MuiDataGrid-cell.MuiDataGrid-withBorderColor':
    {
      fontSize: '13px !important',
      fontWeight: 500,
    },
  '.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-withBorderColor div.MuiBox-root':
    {
      fontSize: '13px !important',
      fontWeight: 500,
    },
  '.MuiDataGrid-columnHeaderTitle, .MuiDataGrid-columnHeaderTitleContainerContent':
    {
      fontSize: '13px !important',
    },
};

export const gridTableBorderStyle = {
  ...gridTableBorderFontStyle,
  // '.MuiDataGrid-cell': {
  //   borderBottom: `1px solid ${gridTableBorderColor}`,
  //   borderColor: `${gridTableBorderColor}`,
  // },
  // '.MuiDataGrid-columnHeader': {
  //   borderBottom: `1px solid ${gridTableBorderColor}`,
  //   borderColor: `${gridTableBorderColor}`,
  // },
  // '.MuiDataGrid-withBorderColor': {
  //   borderColor: `${gridTableBorderColor}`,
  // },
  // '.MuiDataGrid-root .MuiDataGrid-withBorderColor': {
  //   border: `1px solid ${gridTableBorderColor}`,
  //   borderColor: `${gridTableBorderColor}`,
  // },
  // '.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--filledGroup.border-right.ads-creative-table.kmv-header-cell':
  //   {
  //     borderLeft: `1px solid ${gridTableBorderColor}`,
  //   },
  // '.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable.MuiDataGrid-withBorderColor':
  //   {
  //     borderBottom: `2px solid ${gridTableBorderColor}`,
  //   },
  '.MuiDataGrid-cell.MuiDataGrid-withBorderColor:not([data-colindex])': {
    border: 'none',
  },
};

export const TooltipStyle = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    marginTop: '2px',
    backgroundColor: '#fff',
    fontSize: '12px',
    fontWeight: 'normal',
    color: `${theme.palette.grey[800]}`,
    border: `1px solid ${theme.palette.grey[700]}`,
  },
}));
