import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '@/utils/axios';
import { dispatch } from '../store';
import { onError } from './notification';
import { getSessionWithKey } from '@/utils/shopify';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: {
    ltv: null,
    ltv_trends: null,
  },
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA
    getLTVAnalyticsSuccess(state, action) {
      state.isLoading = false;
      state.data.ltv = action.payload;
    },
    getLTVTrendsSuccess(state, action) {
      state.isLoading = false;
      state.data.ltv_trends = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getLTVAnalytics(params) {
  let { store_id, breakdown, time_range_months } = params;
  const secret = getSessionWithKey('secret');

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_ltv', {
        params: {
          store_id,
          secret,
          breakdown,
          time_range_months,
        },
      });

      dispatch(slice.actions.getLTVAnalyticsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function getLTVTrends(params) {
  let { store_id, element } = params;
  const secret = getSessionWithKey('secret');

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_data', {
        params: {
          store_id,
          secret,
          element,
        },
      });

      dispatch(slice.actions.getLTVTrendsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}
