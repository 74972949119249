import { Box, Container, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import useSettings from '@/hooks/useSettings';
import Page from '@/components/Page';

const DashboardPage = ({
  title,
  subtitle = '',
  children,
  maxWidth,
  showAbout = false,
  howToUse,
}) => {
  const { themeStretch } = useSettings();

  return (
    <Page title={title}>
      <Container maxWidth={maxWidth || (themeStretch ? false : 'xl')}>
        <Box sx={{ maxWidth: 'fit-content' }}>
          <Typography
            variant="h4"
            sx={{
              mb: !subtitle && !howToUse ? 3 : 0,
              display: 'inline-flex',
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
          {showAbout && (
            <Typography
              sx={{
                mb: !subtitle ? 3 : 0,
                display: 'inline-flex',
                alignSelf: 'center',
                marginLeft: '10px',
                alignItems: 'center',
                gap: '4px',
                cursor: 'pointer',
                backgroundColor: '#d1d5d8',
                padding: '2px 10px 2px 10px',
                fontSize: '11px',
                borderRadius: '5px',
              }}
              className="page-info-btn"
            >
              <InfoOutlinedIcon
                color="inherit"
                fontSize="small"
                sx={{ width: '14px', height: '14px' }}
              />
              <span>About</span>
            </Typography>
          )}
          {subtitle && (
            <Typography
              variant="caption"
              sx={{ mb: 5, fontSize: '14px', display: 'flex' }}
            >
              {subtitle}
            </Typography>
          )}
          {howToUse && !subtitle && howToUse}
        </Box>
        {children}
      </Container>
    </Page>
  );
};

export default DashboardPage;
