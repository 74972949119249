import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { DataGridPremium } from '@mui/x-data-grid-premium';

const DataGridStyle = styled(Box)(() => ({
  '.MuiDataGrid-virtualScroller': {
    minHeight: '60px !important',
    '& img': {
      maxWidth: 'none !important',
    },
  },
  '& .MuiDataGrid-root .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-root .MuiDataGrid-cell:focus':
    {
      outline: 'none',
    },

  '.text-italic': {
    fontStyle: 'italic !important',
  },

  '.cell-text-bold': {
    fontWeight: 'bold',
  },
  '.MuiDataGrid-footerContainer': {
    position: 'relative',
  },
  '.MuiPagination-root.MuiPagination-text': {
    position: 'sticky',
    right: '10px',
    bottom: '10px',
  },
  '.MuiDataGrid-toolbarContainer button': {
    marginLeft: '10px',
    color: '#15489a !important',
  },
}));

const DataGridPremiumCustom = (props) => {
  return (
    <DataGridStyle className="data-grid-wrapper" sx={{}}>
      <DataGridPremium {...props} />
    </DataGridStyle>
  );
};

export default DataGridPremiumCustom;
