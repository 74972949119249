// ----------------------------------------------------------------------

export default function Progress(theme) {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          overflow: 'hidden',
          backgroundColor: theme.palette.common.white,
        },
        bar: {
          borderRadius: 4,
        },
        colorPrimary: {},
        buffer: {
          backgroundColor: 'transparent',
        },
      },
    },
  };
}
