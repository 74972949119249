import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '@/utils/axios';
import { getSessionWithKey } from '@/utils/shopify';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  error: null,
  data: [],
};

const slice = createSlice({
  name: 'adsOrders',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAdsOrders.pending, (state) => {
        state.isLoading = true;
        state.data = [];
        state.error = null;
      })
      .addCase(getAdsOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getAdsOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error;
      });
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export const getAdsOrders = createAsyncThunk(
  'adsOrders/getAdsPerformance',
  async (params) => {
    const response = await axios.get('/fetch_orderlist', {
      params: {
        ...params,
        secret: getSessionWithKey('secret'),
      },
    });
    return response.data;
  }
);
