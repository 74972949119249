import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import axios from '@/utils/axios';
import { getSessionWithKey } from '@/utils/shopify';
import { trackPageAnalytics } from '@/utils/juneUtil';

const initialState = {
  filters: {
    dateType: 'event',
    model: 'any_click',
    window: '180',
    date: {
      startDate: new Date(),
      endDate: new Date(),
    },
  },
  requestId: '',
  isLoading: true,
  error: null,
  data: null,
};

const slice = createSlice({
  name: 'allSources',
  initialState,
  reducers: {
    setFilter(state, action) {
      state.filters[action.payload.key] = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSources.pending, (state, action) => {
        state.isLoading = true;
        state.error = null;
        state.requestId = action.meta.requestId;
      })
      .addCase(getAllSources.fulfilled, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(getAllSources.rejected, (state, action) => {
        if (state.requestId !== action.meta.requestId) return;
        state.isLoading = false;
        state.data = null;
        state.error = action.error;
      });
  },
});

export default slice.reducer;

export const getAllSources = createAsyncThunk(
  'allSources/getAllSources',
  async ({ type, ...params }, thunkAPI) => {
    trackPageAnalytics(params);

    const response = await axios.get('fetch_all_sources', {
      params: {
        ...params,
        secret: getSessionWithKey('secret'),
      },
    });

    return response.data;
  }
);

// selectors

const selectAllSources = (state) => state.allSources;

export const filtersSelector = createSelector(
  selectAllSources,
  (state) => state.filters
);

export const isLoadingSelector = createSelector(
  selectAllSources,
  (state) => state.isLoading
);

export const errorSelector = createSelector(
  selectAllSources,
  (state) => state.error
);

// export const allSelector = createSelector(
//   selectAllSources,
//   (state) => state.data
// );

export const allSelector = createSelector(selectAllSources, (state) =>
  state.data ? Object.entries(state.data) : null
);

export const { setFilter } = slice.actions;
