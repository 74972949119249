import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '@/utils/axios';
import { dispatch } from '../store';
import { onError } from './notification';
import { getSessionWithKey } from '@/utils/shopify';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: {},
};

const slice = createSlice({
  name: 'adsPerformance',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA
    getAdsPerformanceSuccess(state, action) {
      state.isLoading = false;
      state.data = { ...state.data, ...action.payload };
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getAdsPerformance(params) {
  let { store_id, attribution_type, platform, date_start, date_end } = params;
  const secret = getSessionWithKey('secret');

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_adsperf', {
        params: {
          store_id,
          attribution_type,
          platform,
          date_start,
          date_end,
          secret,
        },
      });

      dispatch(slice.actions.getAdsPerformanceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}
