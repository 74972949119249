import { createSelector, createSlice } from '@reduxjs/toolkit';
// utils
import axios from '@/utils/axios';
import { dispatch } from '../store';
import { onError } from './notification';
import { getSessionWithKey } from '@/utils/shopify';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: {},
  filters: {
    date: {
      startDate: new Date(),
      endDate: new Date(),
    },
  },
};

const slice = createSlice({
  name: 'convRateSource',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get Conv Rate Source Data
    getConvRateSourceSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },

    setFilter(state, action) {
      state.filters[action.payload.key] = action.payload.value;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function fetchConvRateSourceData(params) {
  const secret = getSessionWithKey('secret');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_convratesource_perf', {
        params: {
          ...params,
          secret,
        },
      });
      dispatch(slice.actions.getConvRateSourceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}
export const { setFilter } = slice.actions;

const selectConvRateSource = (state) => state.convRateSource;

export const convRateSourceSelector = createSelector(
  selectConvRateSource,
  (state) => (state.data ? Object.entries(state.data) : null)
);

export const isPageLoadingSelector = createSelector(
  selectConvRateSource,
  (state) => state.isLoading
);

export const errorSelector = createSelector(
  selectConvRateSource,
  (state) => state.error
);

export const filtersSelector = createSelector(
  selectConvRateSource,
  (state) => state.filters
);
