import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Divider, MenuItem, Typography } from '@mui/material';
// hooks
import useAuth from '@/hooks/useAuth';
// components
import MyAvatar from '@/components/MyAvatar';
import MenuPopover from '@/components/MenuPopover';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import useIsMountedRef from '@/hooks/useIsMountedRef';
// import MenuMobile from '@/layouts/main/MenuMobile';
import useResponsive from '@/hooks/useResponsive';
import useOffSetTop from '@/hooks/useOffSetTop';
import { HEADER } from '@/config';
// import navConfig from './NavConfig';
import { IconButtonAnimate } from '@/components/animate';
import Iconify from '@/components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.2, 1),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

export default function NavbarAccount({ isCollapse, onOpenSidebar }) {
  const { user, isDemoAccount, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState(null);
  const isDesktop = useResponsive('up', 'md');
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = async () => {
    try {
      logout();
      if (isDemoAccount) {
        navigate('/', { replace: true });
      }

      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to sign out!', { variant: 'error' });
    }
  };

  return (
    <div style={{ display: 'flex' }}>
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
        style={{ cursor: 'pointer' }}
        onClick={handleOpen}
      >
        <MyAvatar sx={{ width: 25, height: 25 }} />

        <Box
          sx={{
            ml: 1,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
            {user?.role}
          </Typography>
        </Box>
      </RootStyle>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Sign out
        </MenuItem>
      </MenuPopover>

      {/* Hamburger icon for mobile view */}
      {!isDesktop && (
        <IconButtonAnimate
          onClick={onOpenSidebar}
          sx={{
            ml: 1,
            ...(isHome && { color: 'common.white' }),
            ...(isOffset && { color: 'text.primary' }),
          }}
        >
          <Iconify icon={'eva:menu-2-fill'} />
        </IconButtonAnimate>
      )}
    </div>
  );
}
