import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '@/utils/axios';
import { dispatch } from '../store';
import { onError } from './notification';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  activeSubscription: null,
  subscription_callback_done: false,
};

const slice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getActiveSubscriptionSuccess(state, action) {
      state.isLoading = false;
      state.activeSubscription = action.payload;
    },

    createMonthlySubscriptionSuccess(state, action) {
      state.isLoading = false;
      state.return_url =
        typeof action.payload === 'string' ? action.payload : null;
    },

    subscriptionCallbackSuccess(state, action) {
      state.isLoading = false;
      state.subscription_callback_done = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getActiveSubscription(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/subscriptions/active-subscription', {
        params: {
          store_id,
        },
      });

      dispatch(
        slice.actions.getActiveSubscriptionSuccess(
          response.data?.data || response.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function createMonthlySubscription(params) {
  const { store_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/subscriptions/create', {
        store_id: store_id,
        trial_expired: params.trial_expired,
      });

      dispatch(
        slice.actions.createMonthlySubscriptionSuccess(
          response.data?.data?.return_url || response.data
        )
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}

export function subscriptionCallback(params) {
  const { store_id, charge_id } = params;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/subscriptions/callback', {
        store_id: store_id,
        charge_id: charge_id,
      });

      dispatch(slice.actions.subscriptionCallbackSuccess(true));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}
