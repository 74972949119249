export function calculateState(userStatus, integrations) {
  if (userStatus) {
    return 3;
  } else if (
    checkPlatformsIntegration(integrations, ['facebook', 'google']) ||
    hasSixHoursPassed(integrations)
  ) {
    return 2;
  } else if (Array.isArray(integrations) && integrations.length) {
    return 1;
  } else {
    return 0;
  }
}

export function isStateBetweenZeroAndTwo(isActive, integrations) {
  const state = calculateState(isActive, integrations);
  return state >= 0 && state <= 2;
}

export function checkPlatformsIntegration(integrations, platforms) {
  return Array.isArray(integrations) && integrations.length
    ? platforms.every((platform) =>
        new Set(integrations.map((integration) => integration.platform)).has(
          platform
        )
      )
    : false;
}

function hasSixHoursPassed(integrations) {
  if (Array.isArray(integrations) && integrations.length) {
    const sixHoursInMillis = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
    const latestDate = integrations.reduce((latest, current) => {
      return new Date(current.created_at) > new Date(latest.created_at)
        ? current
        : latest;
    }, integrations[0].created_at);
    return new Date() - new Date(latestDate) >= sixHoursInMillis;
  } else {
    return false;
  }
}
