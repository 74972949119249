import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '@/hooks/useAuth';

// @mui
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import { useSelector } from 'react-redux';
import FailedIntegrationsBanner from '@/sections/@dashboard/general/integrations/FailedIntegrationsBanner';
import { trackPageAnalytics } from '@/utils/juneUtil';

// ----------------------------------------------------------------------

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isDemoAccount, isAuthenticated, loginWithDemo } = useAuth();
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const mode = searchParams.get('mode');
  const [requestedLocation, setRequestedLocation] = useState(null);
  const failed_integrations = useSelector(
    (state) => state.user.failed_integrations
  );
  useEffect(() => {
    if (pathname) {
      trackPageAnalytics();
    }
  }, [pathname]);

  useEffect(() => {
    if (mode === 'demo') {
      loginWithDemo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  if (!isAuthenticated && !mode) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to="/connect" />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  // persist params for start trial
  const getStartTrialLink = () => {
    const extractedTrialParams = {};
    // add utm params with old prefix in case already exists
    const addNewParams = [
      { name: 'utm_source', value: 'demo' },
      { name: 'utm_content', value: 'topbanner' },
    ];

    addNewParams.forEach((param) => {
      let i = 0;
      let paramName = param.name;
      while (searchParams.has(paramName)) {
        i++;
        paramName = `${'old_'.repeat(i)}${param.name}`;
      }
      extractedTrialParams[paramName] = param.value;
    });

    // extract utm params
    searchParams.forEach((value, key) => {
      if (key.includes('utm_')) {
        extractedTrialParams[key] = value;
      }
    });

    // extract specific params if exists
    const trialParamsToExtract = [
      'creative_id',
      'creativeid',
      'campaign_id',
      'campaignid',
      'adgroup_id',
      'adgroupid',
      'ad_id',
      'adid',
      'adset_id',
      'adsetid',
      'device',
      'keyword',
      'placement',
      'adset_name',
      'campaign_name',
      'ad_name',
      'adgroup_name',
      'creative_name',
    ];

    trialParamsToExtract.forEach((param) => {
      const value = searchParams.get(param);
      if (value) {
        extractedTrialParams[param] = value;
      }
    });

    // convert obj into query param string
    const trialQueryParams = Object.entries(extractedTrialParams)
      .map((e) => e.join('='))
      .join('&');

    return `https://apps.shopify.com/kendall/?${trialQueryParams}`;
  };

  const trial_href = getStartTrialLink();

  const renderDemoNotice = () => {
    if (!isDemoAccount) {
      if (!failed_integrations || !failed_integrations.length) {
        return;
      }
      return (
        <FailedIntegrationsBanner failed_integrations={failed_integrations} />
      );
    }

    return (
      <Snackbar open anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="info">
          You are viewing a demo. To try Kendall for your own store,{' '}
          <Link href={trial_href} sx={{ color: '#000', cursor: 'pointer' }}>
            {` `}start your free trial!
          </Link>
        </Alert>
      </Snackbar>
    );
  };

  return (
    <>
      {renderDemoNotice()}
      {children}
    </>
  );
}
