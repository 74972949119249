const setSession = (data) => {
  if (data) {
    localStorage.setItem('shopifyAuth', JSON.stringify(data));
    return;
  }

  localStorage.removeItem('shopifyAuth');
};

const getSession = () => {
  return JSON.parse(localStorage.getItem('shopifyAuth'));
};

const getSessionWithKey = (key) => {
  const session = JSON.parse(localStorage.getItem('shopifyAuth'));
  if (!session || !session.hasOwnProperty(key)) {
    return null;
  }

  return session[key];
};

export { setSession, getSession, getSessionWithKey };
