import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '@/utils/axios';
import { dispatch } from '../store';
import { onError } from './notification';
import { getSessionWithKey } from '@/utils/shopify';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  data: [],
};

const slice = createSlice({
  name: 'adsCreativePerformance',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DATA
    getAdsCreativePerformanceSuccess(state, action) {
      state.isLoading = false;
      state.data = [...action.payload];
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getAdsCreativePerformance(params) {
  let { store_id, date_start, date_end, platform, breakdown } = params;
  const secret = getSessionWithKey('secret');

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/fetch_creativesperf', {
        params: {
          store_id,
          secret,
          date_start,
          date_end,
          platform,
          breakdown,
        },
      });

      dispatch(slice.actions.getAdsCreativePerformanceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(onError());
    }
  };
}
