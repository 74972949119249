import { IconButton, InputAdornment, styled } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

const Input = styled(OutlinedInput)({
  marginLeft: 24,
  paddingLeft: 12,
  paddingRight: 4,
  width: 220,
  '> input': { padding: '4.5px 0' },
  backgroundColor: '#fff',
});

const SearchBox = ({ value, onChange }) => {
  return (
    <Input
      size="small"
      className="filter-search"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          {value && (
            <IconButton onClick={() => onChange('')} style={{ padding: 5 }}>
              <CancelIcon style={{ width: 18, height: 18 }} />
            </IconButton>
          )}
          <SearchIcon style={{ width: 20, height: 20 }} />
        </InputAdornment>
      }
      placeholder="Filter by Name..."
    />
  );
};

export default SearchBox;
