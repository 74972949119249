import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

import {
  // filtersSelector,
  getAllSources,
} from '@/redux/slices/allSources';
import { filtersSelector } from '@/redux/slices/attribution';
import useAuth from '@/hooks/useAuth';
import Page from '@/sections/@dashboard/Page';
import AllTable from '@/sections/@dashboard/general/allSources/table/all';
import AdsOrdersDialog from '@/sections/@dashboard/general/adsOrdersDialog';
import { setFilter } from '@/redux/slices/attribution';

const AttributionAllSources = () => {
  const dispatch = useDispatch();
  const filters = useSelector(filtersSelector);
  const { user } = useAuth();
  const [initialFilterSet, setInitialFilterSet] = useState(false);
  const storeId = user?.store_id;

  const [ordersParams, setOrdersParams] = useState();
  const [metaOrders, setMetaOrders] = useState({});

  useEffect(() => {
    dispatch(setFilter({ key: 'model', value: 'first_click' }));
    dispatch(setFilter({ key: 'window', value: '180' }));
    setInitialFilterSet(true);
  }, []);

  const clickOrders = useCallback(
    (params, metaOrdersObj) => {
      setOrdersParams({
        ...params,
        date_start: format(filters.date.startDate, 'yyyy-MM-dd'),
        date_end: format(filters.date.endDate, 'yyyy-MM-dd'),
        date_type: filters.dateType,
        attribution_model: filters.model,
        attribution_window: filters.window,
      });
      setMetaOrders(metaOrdersObj);
    },
    [filters]
  );

  useEffect(() => {
    if (!initialFilterSet) return;
    dispatch(
      getAllSources({
        store_id: storeId,
        date_start: format(filters.date.startDate, 'yyyy-MM-dd'),
        date_end: format(filters.date.endDate, 'yyyy-MM-dd'),
        date_type: filters.dateType,
        attribution_model: filters.model,
        attribution_window: filters.window,
      })
    );
  }, [storeId, filters, dispatch, initialFilterSet]);

  return (
    <Page
      title="Attribution - All Sources"
      subtitle="Identify which campaigns are driving sales and getting you the most new customers"
      maxWidth={'100%'}
    >
      <AllTable clickOrders={clickOrders} />
      <AdsOrdersDialog
        open={!!ordersParams}
        onClose={setOrdersParams}
        params={ordersParams}
        metaOrders={metaOrders}
      />
    </Page>
  );
};

export default AttributionAllSources;
